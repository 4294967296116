<ng-container *ngIf="!notFound; else pageNotFound">
    <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
        <button (click)="back()" mat-button><span translate>tinyAll.back</span></button>
        <app-report-abuse-button *ngIf="!reported" [user]="user" [entityId]="hub?.id" [entityType]="type" [entityName]="hub?.name" [entity]="hub" (reported)="reported = true"></app-report-abuse-button>
    </div>

    <div class="page-container" fxLayout="column">
        <div class="flex-p">
          <app-navigation-menu *ngIf="hub" [hubId]="hub?.id" [showHome]="false" [handler]="hub?.urlHandler" [isDefaultOpen]="true">
          </app-navigation-menu>
        </div>
        <div class="flex-p">
            <mat-card class="custom-card p-0">
                <img mat-card-image src="{{hub?.image || noImage}}" class="m-0" alt="hub" (click)="goToAboutUs()">
            </mat-card>
        </div>

        <div class="flex-p">
            <mat-card class="preview-card custom-card">
                <app-comments-preview #commentsPreview *ngIf="hub" entityType="HUB" [entityName]="hub.name" [entityId]="hub.id"></app-comments-preview>
            </mat-card>
        </div>

        <div *ngFor="let item of items" io-view (inView)="item['show'] = true" fxFlex="100" class="flex-p">
            <app-hub-activity id="item.id" 
                *ngIf="item.show && isActivity(item.contentType)" 
                [activity]="item" 
                [handler]="handler"
                (click)="goToActivity($event)">
            </app-hub-activity>
            <app-hub-category id="item.id" 
                *ngIf="item.show && isCategory(item.contentType)" 
                [category]="item" 
                [handler]="handler"
                (click)="goToCategory($event)">
            </app-hub-category>
        </div>

        <app-company-info-card
            class="footer"
            *ngIf="hub"
            [name]="hub.name"
            [address]="hub.address"
            [phone]="hub.phoneNumber"
            [email]="hub.email"
            [handler]="hub.urlHandler"
            [image]="hub.icon"
        >
            <mat-card-actions class="data-row">
                <div fxLayoutAlign="space-between center" fxLayout="column">
                    <div class="footer-row" fxLayoutAlign="space-between center" fxLayout="row">
                        <span class="count">{{ 'plural.viewsNumber' | pluralTranslate: (hub?.views || 0) | translate:{ views: (hub?.views || 0) } }}</span>
                        <span class="star-button" mat-icon-button><mat-icon>star</mat-icon>{{reviewRate || 0.0 | number : '1.1-1'}} <span [hidden]="!reviewCount" class="count">({{ 'plural.commentsNumber' | pluralTranslate:reviewCount | translate:{ comments: reviewCount } }})</span></span>
                    </div>

                    <div class="footer-row" fxLayoutAlign="space-between center" fxLayout="row">
                        <span class="time"><span translate>labels.created</span> {{hub?.createdOn | timeAgoAlt}}</span>
                        <span class="share-button"><mat-icon (click)="openShare($event)">share</mat-icon></span>
                    </div>
                </div>
            </mat-card-actions>
        </app-company-info-card>

    </div>
</ng-container>
<ng-template #pageNotFound>
    <app-page-not-found (back)="back()"></app-page-not-found>
</ng-template>
<app-loading></app-loading>
