<swiper class="swiper-container" [ngClass]="{'square' : square}" *ngIf="images && images.length > 0" (click)="youtubeVideoControl($event)" >
    <div *ngFor="let image of images" class="swiper-slide">
        <div fxLayout="column" fxLayoutAlign="center center" fxFlexFill class="swiper-card-image-wrapper">
            <mat-card class="swiper-card-image-container">
                <img *ngIf="!validateYoutubeURL(image)" [src]="image" alt="video" loading="lazy" />
                <div class="responsive-youtube-player" *ngIf="canPlay && videoWidthNum >= 320 && validateYoutubeURL(image)">
                    <youtube-player 
                        [videoId]="getYoutubeUrl(image)">
                    </youtube-player>
                </div>
                <a class="add-image-link" 
                    *ngIf="canLink && (videoWidthNum < 320 || !canPlay) && validateYoutubeURL(image)"
                    [href]="image" target="_blank">
                    <img class="placeholder-image" [src]="getYoutubeImage(image)" alt="video" />
                </a>
                <img *ngIf="!canLink && (videoWidthNum < 320 || !canPlay) && validateYoutubeURL(image)"
                    class="placeholder-image" 
                    [src]="getYoutubeImage(image)" alt="video" />
            </mat-card>
        </div>
    </div>
</swiper>