import { Component, OnInit, ViewChild } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { AppSettings } from "../../../app.settings";
import { HttpService } from "../../../service/http.service";
import { ImageService } from "../../../service/image.service";
import { Router, ActivatedRoute } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AuthService } from "../../../auth/auth.serviceV2";
import { MatDialog } from "@angular/material/dialog";
import { Location } from "@angular/common";
import { SentRequestDialogComponent } from "../../../components/sent-request-dialog/sent-request-dialog.component";
import { BaseClientViewComponent } from "../../baseclientview.component";
import QuestionsClientViewUtil from "../../../util/questionsClientViewUtil";
import { ContentType } from "../../../models/content-type-enum";
import { CommentsPreviewComponent } from "../../../components/comments-preview/comments-preview.component";
import { EntityType } from "../../../models/entity-type-enum";
import { RouteConstants } from "../../../app.constants";
import {
  bankTransferImageValidator,
  cashReturnValidator,
  chosenPaymentTypeValidator,
} from "../../../util/form-utils";
import { PaymentType } from "../../../models/payment-type-enum";
import { firstValueFrom } from "rxjs";
import { isEmpty } from "lodash";
import AdditionalChargesUtil from "../../../util/additionalChargesUtil";
import { Meta, Title } from "@angular/platform-browser";
import {
  setActivityPageHead,
  setDefaultPageHead,
} from "../../../util/seo-utils";
import { TranslateService } from "@ngx-translate/core";
import { AzulPaymentFormComponent } from "../../../components/azul-payment-form/azul-payment-form.component";
import { ActCatViewEntity } from "../../../models/activity-category-view";
import QCVUtil from "../../../util/questionsClientViewUtil";
import QMUtil from "../../../util/questionsManagementUtil";
import { HubEntity } from "../../../models/hub";
import {
  CategoryEntity,
  CreateCategoryAccessCommand,
} from "../../../models/category";
import { UserAdditionalInfoService } from "../../authentication/login/user-additional-info.service";
import { MatureContentService } from "../../../service/mature-content.service";
import { HubService } from "../../../service/hub.service";

@Component({
  selector: "app-category-client-view",
  templateUrl: "./category-client-view.component.html",
  styleUrls: ["./category-client-view.component.scss"],
})
export class CategoryClientViewComponent
  extends BaseClientViewComponent
  implements OnInit
{
  @ViewChild("commentsPreview") commentsPreview: CommentsPreviewComponent;
  @ViewChild("requestListTag") requestList;
  @ViewChild("azulPaymentEl") azulPayment: AzulPaymentFormComponent;

  public items: ActCatViewEntity[] = [];
  coordinates = null;
  sendRequestPin;
  type = EntityType.CATEGORY;
  reported = false;
  questionsV1 = [];
  formIsReady = false;

  constructor(
    private authService: AuthService,
    private userAdditionalInfoService: UserAdditionalInfoService,
    private httpService: HttpService,
    public appSettings: AppSettings,
    public router: Router,
    public snackBar: MatSnackBar,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private fb: UntypedFormBuilder,
    protected imageService: ImageService,
    protected translate: TranslateService,
    protected location: Location,
    private matureContentService: MatureContentService,
    private titleService: Title,
    private metaTagService: Meta,
    private hubService: HubService,
  ) {
    super(appSettings, snackBar, imageService, router, location, translate);
  }

  async ngOnInit() {
    await this.authService.isAuthenticated();
    this.user = this.authService.getUserProfile();
    this.hubId = this.route.snapshot.queryParams["hubId"];
    this.categoryId = this.route.snapshot.queryParams["categoryId"];
    this.settings.loadingSpinner = true;
    this.showPaymentResult(this.route.snapshot.queryParams["status"]);
    this.handler = this.route.snapshot.paramMap.get("handler");
    localStorage.removeItem('request_return_url');
    await this.retrieveHub(this.hubId);
    this.hubService.setHub(this.hub);
    this.matureContentService.check(this.hub.adultContent);
    this.initPage();
  }

  private async initPage() {
    try {
      const URL = `${this.apiUrl}/categories/v1/${this.categoryId}`;
      const CATEGORY_RESPONSE = await firstValueFrom(
        this.httpService.doGet(URL)
      );

      if (CATEGORY_RESPONSE != null) {
        this.entity = CATEGORY_RESPONSE;
        this.questionsV1 = QMUtil.transformQuestionsV2ToV1(
          CATEGORY_RESPONSE.questions
        );
        setActivityPageHead(
          this.titleService,
          this.metaTagService,
          CATEGORY_RESPONSE,
          this.hub
        );
        const ACT_URL = `${this.apiUrl}/actcatview/v1/client-search?page=0&size=500&querySearch=hubId:'${this.hubId}' AND contentType!'${ContentType.CATEGORY}' AND content.categoryId:'${this.categoryId}'&sort=order,asc`;
        const response = await this.httpService.doGet(ACT_URL).toPromise();

        if (response.content && response.content.length > 0) {
          this.items = response.content;
        }
      } else {
        setDefaultPageHead(this.titleService, this.metaTagService, true);
      }
      await this.createForm();
      if (
        this.route.snapshot.fragment === "request" &&
        (await this.authService.isAuthenticated())
      ) {
        window.location.hash = "";
        if (this.sendRequestPin) {
          clearTimeout(this.sendRequestPin);
        }
        this.sendRequestPin = setTimeout(() => {
          const data = localStorage.getItem(this.entity.id);
          if (data) {
            const parsedData = JSON.parse(data);
            this.sendRequest({
              ...parsedData,
              clientId: this.user.userId,
            });
          } else {
            this.settings.loadingSpinner = false;
          }
        }, 1000);
      } else {
        this.settings.loadingSpinner = false;
      }
    } catch (error) {
      console.log("Error getting activity: ", error);
      if (error.status === 404) {
        this.notFound = true;
      }
      this.settings.loadingSpinner = false;
    }
  }

  private async retrieveHub(hubId: string) {
    try {
      const hubUrl = `${this.apiUrl}/hubs/v1/${hubId}`;
      const hubResponse: HubEntity = await firstValueFrom(
        this.httpService.doGet(hubUrl)
      );
      if (!hubResponse) return;
      this.hub = hubResponse;
    } catch (error) {
      console.log("Error getting activity: ", error);
    }
  }

  private async createForm() {
    if (!this.entity) {
      return;
    }

    this.form = this.fb.group(
      {
        hubId: [this.hubId],
        categoryId: [this.categoryId ? this.categoryId : ""],
        clientId: [this.user?.userId],
        questionAnswerMap: this.fb.group(
          QuestionsClientViewUtil.createQuestionsForm(this.entity.questions)
        ),
        chosenPaymentType: [""],
        cashReturn: [null],
        paymentImage: [""],
        totalPrice: [0],
        promoCode: [''],
        promoCodeApplied: [false],
      },
      {
        validators: [
          bankTransferImageValidator,
          cashReturnValidator,
          chosenPaymentTypeValidator(this.hub.supportedPaymentTypes),
        ],
      }
    );

    if (this.entity.fullnameRequired) {
      this.form.addControl(
        'clientName',
        this.fb.control(this.user?.name || '', [Validators.required])
      );
    }

    if (this.entity.phoneNumberRequired) {
      this.form.addControl(
        "clientPhoneNumber",
        this.fb.control(this.user?.cellPhone || "", [
          Validators.required,
          Validators.pattern(
            "^(([1-9][0-9]{2})|[1-9][0-9]{2})[1-9][0-9]{2}[0-9]{4}$"
          ),
        ])
      );
    }

    if (this.entity.gpsRequired) {
      this.form.addControl(
        "location",
        this.fb.control({ value: "", disabled: true }, [Validators.required])
      );

      const permissionStatus = await navigator.permissions.query({
        name: "geolocation",
      });
      if (permissionStatus && permissionStatus.state === "granted") {
        this.getCurrentPosition().then((position: any) => {
          let COORDINATES;
          if (position) {
            COORDINATES = {
              coordinates: [
                position.coords.latitude,
                position.coords.longitude,
              ],
              type: "Point",
            };
            this.form.get("location").setValue(COORDINATES);
          }
        });
      }
    }

    if (this.entity.addressRequired) {
      this.form.addControl(
        'choosenLocation',
        this.fb.control('', [
          Validators.required,
        ])
      );
    }

    this.formIsReady = true;
  }

  get chosenPaymentType() {
    return this.form.get("chosenPaymentType") as UntypedFormControl;
  }

  get totalPrice() {
    return this.form.get("totalPrice") as UntypedFormControl;
  }

  get questionAnswerMap() {
    return this.form.get("questionAnswerMap") as UntypedFormGroup;
  }

  getCurrentPosition(options = {}) {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject, options);
    });
  }

  getQuestionsLength(): number {
    if (!this.form || !this.form.get("questionAnswerMap")) {
      return 0;
    }
    const GROUP = this.form.get("questionAnswerMap") as UntypedFormGroup;
    return Object.keys(GROUP.controls).length;
  }

  async sendRequest(body?: any) {
    if (!this.entity || !this.form || this.locked) {
      this.settings.loadingSpinner = false;
      return;
    }

    if (!(await this.authService.isAuthenticated())) {
      localStorage.setItem("return_url", `${this.router.url}#request`);
      localStorage.setItem('request_return_url', `${this.router.url}#request`);
      localStorage.setItem(
        this.entity.id,
        JSON.stringify(this.getRequestBody(body))
      );
      await this.router.navigate([RouteConstants.auth]);
      this.settings.loadingSpinner = false;
      return;
    }

    this.settings.loadingSpinner = true;

    if (!await this.userAdditionalInfoService.check(`${this.router.url}#request`)) {
      localStorage.setItem(
        this.entity.id,
        JSON.stringify(this.getRequestBody(body))
      );
      this.settings.loadingSpinner = false;
      return;
    }

    const url = this.apiUrl + `/categories/v1/request-access`;
    const rawValues = this.getRequestBody(body);

    const {
      clientId,
      hubId,
      categoryId,
      questionAnswerMap,
      location,
      totalPrice,
      clientPhoneNumber,
      clientName,
      chosenPaymentType,
      cashReturn,
      paymentImage,
      choosenLocation,
      promoCode,
      promoCodeApplied,
    } = rawValues;

    const requestBody: CreateCategoryAccessCommand = {
      clientId,
      hubId,
      categoryId,
      questionAnswerMap: QCVUtil.transformAnswersV1ToV2(questionAnswerMap, this.entity.questions),
      location,
      totalPrice,
      clientPhoneNumber,
      clientName,
      chosenPaymentType,
      cashReturn,
      paymentImage,
      choosenLocation,
      ...(promoCodeApplied && { promoCode })
    };

    try {
      const response: CategoryEntity = await firstValueFrom(
        this.httpService.doPost(url, requestBody)
      );
      this.locked = true;
      this.form.reset();
      this.createForm();
      if (rawValues.chosenPaymentType === PaymentType.TINYALL_PAY) {
        this.azulPayment?.azulPay(response);
        return;
      }
      this.openDialog();
    } catch (error) {
      this.showSnackBar("Error Processing Your Request", "OK", 2000);
    } finally {
      localStorage.removeItem(this.entity.id);
      this.settings.loadingSpinner = false;
    }
  }

  private getRequestBody(body) {
    if (body) {
      return body;
    }
    const rawValues = this.form.getRawValue();
    rawValues.questionAnswerMap = QuestionsClientViewUtil.cleanContent(
      this.entity.questions,
      rawValues
    );
    rawValues["totalPrice"] =
      Number(rawValues["totalPrice"]) > 0
        ? Number(rawValues["totalPrice"]).toFixed(2)
        : "0";
    return AdditionalChargesUtil.creanPaymentContent(rawValues);
  }

  onCancel() {
    this.locked = false;
  }

  onHasOpen(event: any) {
    this.locked = event;
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(SentRequestDialogComponent, {
      minWidth: "240px",
      data: {
        title: "titles.request",
        content: "messages.operation_successful",
        hasRichText: !!this.entity.newRequestMessage,
        richContent: this.entity.newRequestMessage
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.requestList.search();
      setTimeout(() => {
        const element = document.getElementById("request-list");
        if (element !== null) {
          element.scrollIntoView();
        }
      }, 300);
    });
  }

  public goToActivity(item: any): void {
    const start = this.handler
      ? `${this.handler}/${item.type}`
      : `/~${item.type}`;
    let url = `/${start}/client-view?hubId=${this.hubId}&activityId=${item.id}`;
    if (item.categoryId) {
      url = `/${start}/client-view?hubId=${this.hubId}&categoryId=${item.categoryId}&activityId=${item.id}`;
    }
    this.router.navigateByUrl(url).then();
  }

  isActivity(type: string): boolean {
    return type.startsWith("ACTIVITY");
  }

  get showPriceSummary() {
    return (
      !this.locked &&
      this.entity &&
      this.entity.restricted &&
      this.form &&
      (this.getQuestionsLength() > 0 ||
        !isEmpty(this.entity.additionalCharges || {}))
    );
  }

  get showPaymentMethods() {
    return (
      !this.locked &&
      this.form &&
      this.hub &&
      this.entity &&
      this.entity.restricted &&
      this.hub.supportedPaymentTypes &&
      this.hub.supportedPaymentTypes.length > 0 &&
      this.totalPrice.value
    );
  }

  get showSendRequest() {
    return (
      !this.locked &&
      this.form &&
      (this.chosenPaymentType.value !== PaymentType.TINYALL_PAY ||
        !this.totalPrice.value)
    );
  }

  get disableSendRequest() {
    return !this.form || this.form.invalid;
  }
}
