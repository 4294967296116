

<div class="header no-print" fxLayout="row" fxLayoutAlign="space-between center">
    <button (click)="close()" mat-button><span translate>tinyAll.back</span></button>
    <h2 class="title" *ngIf="!parentData.isTicket" translate>titles.qr_generator</h2>
    <h2 class="title" *ngIf="parentData.isTicket" translate>labels.ticket</h2>
</div>

<div class="page-container" fxLayout="row wrap" fxLayoutAlign="center start">
    <app-qr-generator [text]="parentData.qrCode" [isMenu]="parentData.isMenu" [imageUrl]="parentData.imageUrl" [isTicket]="parentData.isTicket" [ticketStatus]="parentData.ticketStatus">
    </app-qr-generator>
</div>



