import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  AfterViewInit,
} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AppSettings } from '../../../../app.settings';
import { Settings } from '../../../../app.settings.model';
import { MenuService } from '../menu.service';
import { AuthService } from '../../../../auth/auth.serviceV2';
import { RouteConstants } from '../../../../app.constants';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-vertical-menu',
  templateUrl: './vertical-menu.component.html',
  styleUrls: ['./vertical-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [MenuService],
})
export class VerticalMenuComponent implements OnInit, AfterViewInit {
  @Input('menuItems') menuItems;
  @Input('menuParentId') menuParentId;
  parentMenu: Array<any>;
  public settings: Settings;
  userLoggedIn = false;

  constructor(
    public appSettings: AppSettings,
    public menuService: MenuService,
    public router: Router,
    private auth: AuthService,
    protected snackBar: MatSnackBar,
    public translate: TranslateService
  ) {
    this.settings = this.appSettings.settings;
  }

  async ngOnInit() {
    this.parentMenu = this.menuItems.filter(
      (item) => item.parentId == this.menuParentId
    );
    this.userLoggedIn = await this.auth.isAuthenticated();
  }

  ngAfterViewInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.settings.fixedHeader) {
          let mainContent = document.getElementById('main-content');
          if (mainContent) {
            mainContent.scrollTop = 0;
          }
        } else {
          document.getElementsByClassName(
            'mat-drawer-content'
          )[0].scrollTop = 0;
        }
      }
    });
  }

  onClick(menuId) {
    this.menuService.toggleMenuItem(menuId);
    this.menuService.closeOtherSubMenus(this.menuItems, menuId);
  }

  closeMenu() {
    this.menuService.closeMenu();
  }

  async goToUserPreferences() {
    await this.router.navigateByUrl(
      `${RouteConstants.settings}/user-preferences`
    );
  }

  async logIn() {
    const result = await this.auth.getLoginRedirectResult();
    if (result) {
      this.userLoggedIn = true;
    } else {
      localStorage.setItem('return_url', `${this.router.url}`);
      await this.router.navigate([RouteConstants.auth]);
    }
  }

  public async logOut() {
    if (this.userLoggedIn) {
      const user = this.auth.getUserProfile();
      await this.auth.logout();
      this.userLoggedIn = false;
      this.showSnackBar(
        this.translate.instant('messages.goodbye', {
          value: user.name || '',
        }),
        'OK',
        2000
      );
    } else {
      localStorage.setItem(RouteConstants.returnUrl, this.router.url);
    }
    await this.router.navigateByUrl(RouteConstants.home);
  }

  showSnackBar(message, action, duration) {
    this.snackBar.open(message, action, {
      duration: duration,
    });
  }
}
