<ng-container *ngIf="!notFound; else pageNotFound">
    <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
        <button (click)="back()" mat-button><span translate>tinyAll.back</span></button>
        <app-report-abuse-button *ngIf="!reported" [user]="user" [entityId]="activityId" [entityType]="type" [entityName]="entity?.name" [entity]="entity" (reported)="reported = true"></app-report-abuse-button>
    </div>
    
    <div class="page-container" fxLayout="row wrap" fxLayoutAlign="center start">
        <div class="flex-p" fxFlex="100">
            <app-navigation-menu *ngIf="hubId && handler" [hubId]="hubId" [handler]="handler">
            </app-navigation-menu>
        </div>
        <div class="flex-p" fxFlex="100">
            <mat-card class="card-container">
                <mat-card-header>
                    <mat-card-title class="activity-title">{{entity?.name}}</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <app-swiper-view *ngIf="entity?.images && entity.images.length > 0" 
                        [images]="entity.images"
                        [canPlay]="true"
                        [canLink]="true">
                    </app-swiper-view>
                </mat-card-content>
            </mat-card>
        </div>
        
        <div class="flex-p" fxFlex="100">
            <mat-card class="preview-card custom-card">
                <app-comments-preview #commentsPreview *ngIf="entity" entityType="ACTIVITY_INFO" [entityName]="entity.name" [entityId]="entity.id"></app-comments-preview>
            </mat-card>
        </div>

        <app-collapsible-description [text]="entity?.infoText" [collapsible]="true"></app-collapsible-description>
        <app-company-info-card
            *ngIf="hub"
            [name]="hub.name"
            [address]="hub.address"
            [phone]="hub.phoneNumber"
            [email]="hub.email"
            [handler]="hub.urlHandler"
            [image]="hub.icon"
        >
            <app-social-card-actions 
                *ngIf="entity && commentsPreview" 
                [title]="entity.name"
                [description]="entity.infoText"
                [images]="entity.images"
                [preview]="commentsPreview"
                [views]="entity.views"
                [hasRequest]="false"
                [id]="activityId"
                [createdOn]="entity.createdOn"
            >
            </app-social-card-actions>
        </app-company-info-card>
    </div>
</ng-container>
<ng-template #pageNotFound>
    <app-page-not-found (back)="back()"></app-page-not-found>
</ng-template>
<app-loading></app-loading>
