import {
  Component,
  OnInit,
  ViewChild,
  HostListener,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AppSettings } from '../app.settings';
import { Settings } from '../app.settings.model';
import { MenuService } from '../theme/components/menu/menu.service';
import { RouteConstants } from '../app.constants';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AuthService } from '../auth/auth.serviceV2';
import {
  faFacebook,
  faYoutube,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons';
import { Subscription } from 'rxjs';
import { AccountStatus } from '../models/account-status-enum';
import { HubService } from '../service/hub.service';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
  providers: [MenuService],
})
export class PagesComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('sidenav') sidenav: any;

  readonly faFacebook = faFacebook;
  readonly faInstagram = faInstagram;
  readonly faYoutube = faYoutube;

  public perfectScrollBarConfig: PerfectScrollbarConfigInterface = {};
  public settings: Settings;
  public menus = ['vertical', 'horizontal'];
  public menuOption: string;
  public menuTypeOption: string;
  public isStickyMenu = false;
  public lastScrollTop = 0;
  public showBackToTop = false;
  public scrolledContent: any;
  private defaultMenu: string; // declared for return default menu when window resized
  user: any;
  subscription: Subscription;

  constructor(
    public appSettings: AppSettings,
    public router: Router,
    private menuService: MenuService,
    private authService: AuthService,
    protected hubService: HubService
  ) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    if (window.innerWidth <= 768) {
      this.settings.menu = 'vertical';
      this.settings.sidenavIsOpened = false;
      this.settings.sidenavIsPinned = false;
    }
    this.menuOption = this.settings.menu;
    this.menuTypeOption = this.settings.menuType;
    this.defaultMenu = this.settings.menu;
  }

  ngAfterViewInit() {
    this.menuService.setSidenav(this.sidenav);
    this.user = this.authService.getUserProfile();
    this.subscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.user = this.authService.getUserProfile();
        if (!this.settings.sidenavIsPinned) {
          this.sidenav.close();
        }
        if (window.innerWidth <= 768) {
          this.sidenav.close();
        }
      }
    });
    if (this.settings.menu === 'vertical') {
      this.menuService.expandActiveSubMenu(
        this.menuService.getVerticalMenuItems()
      );
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  get AccountStatus() {
    return AccountStatus;
  }

  public chooseMenu() {
    this.settings.menu = this.menuOption;
    this.defaultMenu = this.menuOption;
    this.router.navigate([RouteConstants.home]).then();
  }

  public toggleSidenav() {
    this.sidenav.toggle();
  }

  public onPsScrollY(event) {
    this.scrolledContent = event.target;
    this.scrolledContent.scrollTop > 300
      ? (this.showBackToTop = true)
      : (this.showBackToTop = false);
    if (this.settings.menu === 'horizontal') {
      if (this.settings.fixedHeader) {
        const currentScrollTop =
          this.scrolledContent.scrollTop > 56
            ? this.scrolledContent.scrollTop
            : 0;
        currentScrollTop > this.lastScrollTop
          ? (this.isStickyMenu = true)
          : (this.isStickyMenu = false);
        this.lastScrollTop = currentScrollTop;
      } else {
        this.scrolledContent.scrollTop > 56
          ? (this.isStickyMenu = true)
          : (this.isStickyMenu = false);
      }
    }
  }

  @HostListener('window:resize')
  public onWindowResize(): void {
    if (window.innerWidth <= 768) {
      this.settings.sidenavIsOpened = false;
      this.settings.sidenavIsPinned = false;
      this.settings.menu = 'vertical';
    } else {
      this.defaultMenu === 'horizontal'
        ? (this.settings.menu = 'horizontal')
        : (this.settings.menu = 'vertical');
    }
  }

  public closeSubMenus() {
    const menu = document.querySelector('.sidenav-menu-outer');
    if (menu) {
      for (let i = 0; i < menu.children[0].children.length; i++) {
        const child = menu.children[0].children[i];
        if (child) {
          if (child.children[0].classList.contains('expanded')) {
            child.children[0].classList.remove('expanded');
            child.children[1].classList.remove('show');
          }
        }
      }
    }
  }

  async logIn(event) {
    event.preventDefault();
    const result = await this.authService.getLoginRedirectResult();
    if (result) {
      window.open(RouteConstants.home, '_blank');
    } else {
      localStorage.setItem('return_url', `${this.router.url}`);
      window.open(RouteConstants.auth, '_blank');
    }
  }

  async logInAndKeepUrl() {
    const result = await this.authService.getLoginRedirectResult();
    if (!result) {
      localStorage.setItem('return_url', `${this.router.url}`);
      await this.router.navigate([RouteConstants.auth]);
    }
  }

  get isHub() {
    return (
      (this.hubService.hub?.urlHandler &&
        location.pathname.endsWith(this.hubService.hub?.urlHandler)) ||
      location.pathname.endsWith('/client-view') ||
      location.pathname.endsWith('/about-us') ||
      location.pathname.endsWith('/category-client-view')
    );
  }
}
