import { Component, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AppSettings } from '../../../app.settings';
import { HttpService } from '../../../service/http.service';
import { ImageService } from '../../../service/image.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../../../auth/auth.serviceV2';
import { MatDialog } from '@angular/material/dialog';
import { SentRequestDialogComponent } from '../../../components/sent-request-dialog/sent-request-dialog.component';
import { DatetimeAdapter } from '@mat-datetimepicker/core';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import DAYS from '../../../util/days';
import { BaseClientViewComponent } from '../../baseclientview.component';
import QCVUtil from '../../../util/questionsClientViewUtil';
import { CommentsPreviewComponent } from '../../../components/comments-preview/comments-preview.component';
import { EntityType } from '../../../models/entity-type-enum';
import { RouteConstants } from '../../../app.constants';
import {
  bankTransferImageValidator,
  cashReturnValidator,
  chosenPaymentTypeValidator,
} from '../../../util/form-utils';
import { PaymentType } from '../../../models/payment-type-enum';
import { firstValueFrom } from 'rxjs';
import { isEmpty } from 'lodash';
import AdditionalChargesUtil from '../../../util/additionalChargesUtil';
import { Meta, Title } from '@angular/platform-browser';
import {
  setActivityPageHead,
  setDefaultPageHead,
} from '../../../util/seo-utils';
import { AzulPaymentFormComponent } from '../../../components/azul-payment-form/azul-payment-form.component';
import { ActivityReservationEntity, CreateReservationCommand, ReservationEntity } from '../../../models/activity-reservation';
import { HubEntity } from '../../../models/hub';
import QMUtil from '../../../util/questionsManagementUtil';
import { UserAdditionalInfoService } from '../../authentication/login/user-additional-info.service';
import { MatureContentService } from '../../../service/mature-content.service';
import { HubService } from '../../../service/hub.service';

@Component({
  selector: 'app-actrsvn-client-view',
  templateUrl: './activity-reservation-client-view.component.html',
  styleUrls: ['./activity-reservation-client-view.component.scss'],
})
export class ActivityReservationClientViewComponent
  extends BaseClientViewComponent
  implements OnInit
{
  @ViewChild('commentsPreview') commentsPreview: CommentsPreviewComponent;
  @ViewChild('requestListTag') requestList;
  @ViewChild('azulPaymentEl') azulPayment: AzulPaymentFormComponent;

  readonly activityType = 'reservation';
  coordinates = null;
  daySelected = new Date();
  daySchedule = [];
  busySlots = new Set<string>();
  blockedHours = new Set<string>();
  daysOff = new Set<string>();
  reservationDuration = 15;
  scheduleLoading = false;
  daysWithSchedule = new Set<string>();
  offsetMinutes;
  entity: ActivityReservationEntity;
  sendRequestPin;

  type = EntityType.ACTIVITY_RESERVATION;
  reported = false;
  questionsV1 = [];
  formIsReady = false;

  constructor(
    private authService: AuthService,
    private userAdditionalInfoService: UserAdditionalInfoService,
    private httpService: HttpService,
    protected appSettings: AppSettings,
    protected router: Router,
    protected snackBar: MatSnackBar,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private fb: UntypedFormBuilder,
    protected imageService: ImageService,
    public _adapter: DatetimeAdapter<Date>,
    protected translate: TranslateService,
    protected location: Location,
    private matureContentService: MatureContentService,
    private titleService: Title,
    private metaTagService: Meta,
    private hubService: HubService,
  ) {
    super(appSettings, snackBar, imageService, router, location, translate);
    _adapter.setLocale(translate.getDefaultLang());
    (_adapter as any)._delegate.setLocale(translate.getDefaultLang());
  }

  async ngOnInit() {
    await this.authService.isAuthenticated();
    this.user = this.authService.getUserProfile();
    this.hubId = this.route.snapshot.queryParams['hubId'];
    this.categoryId = this.route.snapshot.queryParams['categoryId'];
    this.activityId = this.route.snapshot.queryParams['activityId'];
    this.settings.loadingSpinner = true;
    this.showPaymentResult(this.route.snapshot.queryParams['status']);
    this.handler = this.route.snapshot.paramMap.get('handler');
    localStorage.removeItem('request_return_url');
    await this.retrieveHub(this.hubId);
    this.hubService.setHub(this.hub);
    this.matureContentService.check(this.hub.adultContent);
    this.initPage();
  }

  private async initPage() {
    try {
      const activityUrl = `${this.apiUrl}/activity-reservation/v1/${this.activityId}`;

      const activityResponse: ActivityReservationEntity = await firstValueFrom(
        this.httpService.doGet(activityUrl)
      );

      if (activityResponse != null) {
        this.entity = activityResponse;
        this.questionsV1 = QMUtil.transformQuestionsV2ToV1(
          activityResponse.questions
        );
        setActivityPageHead(
          this.titleService,
          this.metaTagService,
          activityResponse,
          this.hub
        );
        this.reservationDuration = this.entity.scheduleBean.durationInMinutes;
        this.getDaysWithSchedule(this.entity.scheduleBean.entries);
        this.daysOff = new Set(this.entity.exceptions);
        await this.getBusySlots(activityResponse.slot);
        this.onDateChange(this.daySelected);
      } else {
        setDefaultPageHead(this.titleService, this.metaTagService, true);
      }

      await this.createForm();
      if (
        this.route.snapshot.fragment === 'request' &&
        (await this.authService.isAuthenticated())
      ) {
        window.location.hash = '';
        if (this.sendRequestPin) {
          clearTimeout(this.sendRequestPin);
        }
        this.sendRequestPin = setTimeout(() => {
          const data = localStorage.getItem(this.entity.id);
          if (data) {
            const parsedData = JSON.parse(data);
            this.sendRequest({
              ...parsedData,
              clientId: this.user.userId,
            });
          } else {
            this.settings.loadingSpinner = false;
          }
        }, 1000);
      } else {
        this.settings.loadingSpinner = false;
      }
    } catch (error) {
      console.log('Error getting activity: ', error);
      if (error.status === 404) {
        this.notFound = true;
      }
      this.settings.loadingSpinner = false;
    }
  }

  private async retrieveHub(hubId: string) {
    try {
      const hubUrl = `${this.apiUrl}/hubs/v1/${hubId}`;
      const hubResponse: HubEntity = await firstValueFrom(
        this.httpService.doGet(hubUrl)
      );
      if (!hubResponse) return;
      this.hub = hubResponse;
    } catch (error) {
      console.log('Error getting activity: ', error);
    }
  }

  private async createForm() {
    if (!this.entity) {
      return;
    }

    this.form = this.fb.group(
      {
        hubId: [this.hubId],
        activityId: [this.activityId],
        clientId: [this.user?.userId],
        status: ['OPEN'],
        reservationTime: [null, Validators.required],
        questionAnswerMap: this.fb.group(
          QCVUtil.createQuestionsForm(this.entity.questions)
        ),
        chosenPaymentType: [''],
        cashReturn: [null],
        paymentImage: [''],
        totalPrice: [0],
        promoCode: [''],
        promoCodeApplied: [false],
      },
      {
        validators: [
          bankTransferImageValidator,
          cashReturnValidator,
          chosenPaymentTypeValidator(this.hub.supportedPaymentTypes),
        ],
      }
    );

    if (this.entity.fullnameRequired) {
      this.form.addControl(
        'clientName',
        this.fb.control(this.user?.name || '', [Validators.required])
      );
    }

    if (this.entity.phoneNumberRequired) {
      this.form.addControl(
        'clientPhoneNumber',
        this.fb.control(this.user?.cellPhone || '', [
          Validators.required,
          Validators.pattern(
            '^(([1-9][0-9]{2})|[1-9][0-9]{2})[1-9][0-9]{2}[0-9]{4}$'
          ),
        ])
      );
    }

    if (this.entity.gpsRequired) {
      this.form.addControl(
        'location',
        this.fb.control({ value: '', disabled: true }, [Validators.required])
      );

      const permissionStatus = await navigator.permissions.query({
        name: 'geolocation',
      });
      if (permissionStatus && permissionStatus.state === 'granted') {
        this.getCurrentPosition().then((position: any) => {
          let COORDINATES;
          if (position) {
            COORDINATES = {
              coordinates: [
                position.coords.latitude,
                position.coords.longitude,
              ],
              type: 'Point',
            };
            this.form.get('location').setValue(COORDINATES);
          }
        });
      }
    }

    if (this.entity.addressRequired) {
      this.form.addControl(
        'choosenLocation',
        this.fb.control('', [
          Validators.required,
        ])
      );
    }

    this.formIsReady = true;
  }

  get chosenPaymentType() {
    return this.form.get('chosenPaymentType') as UntypedFormControl;
  }

  get totalPrice() {
    return this.form.get('totalPrice') as UntypedFormControl;
  }

  get questionAnswerMap() {
    return this.form.get('questionAnswerMap') as UntypedFormGroup;
  }

  get reservationTime() {
    return this.form.get('reservationTime') as UntypedFormControl;
  }

  async getBusySlots(capacity: number) {
    const activityReservationsUrl = `${this.apiUrl}/activity-reservation/v1/reservation-taken-dates/${this.entity.id}?paged=false&unpaged=true`;
    const activityReservationResponse = await firstValueFrom(
      this.httpService.doGet(activityReservationsUrl)
    );
    if (activityReservationResponse) {
      const counter = {};
      for (const RESERVATION of activityReservationResponse.content) {
        if (counter[RESERVATION]) {
          counter[RESERVATION] += 1;
        } else {
          counter[RESERVATION] = 1;
        }

        if (counter[RESERVATION] === capacity) {
          this.busySlots.add(RESERVATION);
        }
      }
    }
  }

  getDaysWithSchedule(entries: any) {
    this.daysWithSchedule.clear();
    for (const ENTRY of entries) {
      this.daysWithSchedule.add(ENTRY.dayOfWeek);
    }
  }

  getCurrentPosition(options = {}) {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject, options);
    });
  }

  getQuestionsLength(): number {
    if (!this.form || !this.form.get('questionAnswerMap')) {
      return 0;
    }
    const GROUP = this.form.get('questionAnswerMap') as UntypedFormGroup;
    return Object.keys(GROUP.controls).length;
  }

  async sendRequest(body?: any) {
    if (!this.entity || !this.form || this.locked) {
      this.settings.loadingSpinner = false;
      return;
    }

    if (!(await this.authService.isAuthenticated())) {
      localStorage.setItem('return_url', `${this.router.url}#request`);
      localStorage.setItem('request_return_url', `${this.router.url}#request`);
      localStorage.setItem(
        this.entity.id,
        JSON.stringify(this.getRequestBody(body))
      );
      await this.router.navigate([RouteConstants.auth]);
      this.settings.loadingSpinner = false;
      return;
    }

    this.settings.loadingSpinner = true;

    if (!await this.userAdditionalInfoService.check(`${this.router.url}#request`)) {
      localStorage.setItem(
        this.entity.id,
        JSON.stringify(this.getRequestBody(body))
      );
      this.settings.loadingSpinner = false;
      return;
    }

    const url = `${this.apiUrl}/activity-reservation/v1/reservation`;
    const rawValues = this.getRequestBody(body);

    const {
      clientId,
      hubId,
      activityId,
      questionAnswerMap,
      location,
      totalPrice,
      clientPhoneNumber,
      clientName,
      chosenPaymentType,
      cashReturn,
      paymentImage,
      status,
      reservationTime,
      choosenLocation,
      promoCode,
      promoCodeApplied,
    } = rawValues;

    const requestBody: CreateReservationCommand = {
      clientId,
      hubId,
      activityId,
      questionAnswerMap: QCVUtil.transformAnswersV1ToV2(questionAnswerMap, this.entity.questions),
      location,
      totalPrice,
      clientPhoneNumber,
      clientName,
      chosenPaymentType,
      cashReturn,
      paymentImage,
      status,
      reservationTime,
      choosenLocation,
      ...(promoCodeApplied && { promoCode })
    };

    try {
      const response: ReservationEntity = await firstValueFrom(
        this.httpService.doPost(url, requestBody)
      );
      this.locked = true;
      this.form.reset();
      this.createForm();
      if (rawValues.chosenPaymentType === PaymentType.TINYALL_PAY) {
        this.azulPayment?.azulPay(response);
        return;
      }
      this.openDialog();
    } catch (error) {
      this.showSnackBar('Error Processing Your Request', 'OK', 2000);
    } finally {
      localStorage.removeItem(this.entity.id);
      this.settings.loadingSpinner = false;
    }
  }

  private getRequestBody(body) {
    if (body) {
      return body;
    }
    const rawValues = this.form.getRawValue();
    rawValues.questionAnswerMap = QCVUtil.cleanContent(
      this.entity.questions,
      rawValues
    );
    rawValues['totalPrice'] =
      Number(rawValues['totalPrice']) > 0
        ? Number(rawValues['totalPrice']).toFixed(2)
        : '0';
    return AdditionalChargesUtil.creanPaymentContent(rawValues);
  }

  async onCancel() {
    this.form.get('reservationTime').setValue(null);
    this.locked = false;
  }

  onHasOpen(event: any) {
    this.locked = event;
  }

  // Reservation
  onDateChange(date: Date) {
    this.daySelected = date;
    this.scheduleLoading = true;

    if (this.daySelected) {
      this.daySchedule = [];
      this.blockedHours.clear();
      const LAST_UPDATE_DATE = this.entity.updatedOn.split('T')[0];
      if (
        this.entity &&
        this.entity.scheduleBean &&
        this.entity.scheduleBean.entries
      ) {
        const day = DAYS[this._adapter.getDayOfWeek(this.daySelected)];
        for (const ENTRY of this.entity.scheduleBean.entries) {
          if (ENTRY.dayOfWeek === day) {
            const HOURS = [];
            for (const INTERVAL of ENTRY.intervals) {
              const SPLITTED_START = INTERVAL.start.split('T')[1].split(':');
              const SPLITTED_END = INTERVAL.end.split('T')[1].split(':');
              const START_DATE = new Date(
                `${LAST_UPDATE_DATE}T${SPLITTED_START[0]}:${SPLITTED_START[1]}:00.000Z`
              );
              const END_DATE = new Date(
                `${LAST_UPDATE_DATE}T${SPLITTED_END[0]}:${SPLITTED_END[1]}:00.000Z`
              );
              const START = this._adapter.getHour(START_DATE);
              const END = this._adapter.getHour(END_DATE);
              this.offsetMinutes = this._adapter.getMinute(START_DATE);
              for (let i = START; i < END; i++) {
                HOURS.push(i);
              }
            }
            this.daySchedule = HOURS;
            break;
          }
        }
      }
    }
    this.scheduleLoading = false;
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(SentRequestDialogComponent, {
      minWidth: '240px',
      data: {
        title: 'titles.reservation',
        content: 'messages.operation_successful',
        hasRichText: !!this.entity.newRequestMessage,
        richContent: this.entity.newRequestMessage
      },
    });

    dialogRef.afterClosed().subscribe((_result) => {
      this.requestList.search();
      setTimeout(() => {
        const element = document.getElementById('request-list');
        if (element !== null) {
          element.scrollIntoView();
        }
      }, 300);
    });
  }

  get showPriceSummary() {
    return (
      !this.locked &&
      this.entity &&
      this.form &&
      (this.getQuestionsLength() > 0 ||
        !isEmpty(this.entity.additionalCharges || {}))
    );
  }

  get showPaymentMethods() {
    return (
      !this.locked &&
      this.form &&
      this.hub &&
      this.hub.supportedPaymentTypes &&
      this.hub.supportedPaymentTypes.length > 0 &&
      this.totalPrice.value
    );
  }

  get showSendRequest() {
    return (
      !this.locked &&
      this.form &&
      (this.chosenPaymentType.value !== PaymentType.TINYALL_PAY ||
        !this.totalPrice.value)
    );
  }

  get disableSendRequest() {
    return !this.form || this.form.invalid;
  }
}
