<div class="page-container" fxLayout="column">
    <form *ngIf="form" [formGroup]="form" class="main-order-info">
        <app-order-general-info *ngIf="order"
            [order]="order"
            [activity]="activity"
            [status]="status"
            [statusOptions]="getAvailableStatus(order.status)"
            (statusChanged)="sendRequest()">
        </app-order-general-info>
    </form>

    <div *ngIf="order && order.additionalIndications && activity" class="my-2" fxFlex="100">
        <mat-card class="card-container border-2 border-accent">
            <mat-card-content>
                <mat-card-title class="text-xl text-primary" translate>labels.additional_indications</mat-card-title>
                <p class="info-p-box text-lg">{{order.additionalIndications}}</p>
            </mat-card-content>
        </mat-card>
    </div>

    <app-menu-order-dishes-list *ngIf="order && activity"
        #menuOrderDishesList
        [owner]="false"
        [order]="order"
        [activityId]="activity.id"
        [hubId]="order.hubId"
        [userId]="activity.userId"
        [initialTip]="order.tippingPercentage">
    </app-menu-order-dishes-list>

    <app-chosen-payment-method *ngIf="order && order.chosenPaymentType"
        [chosenPaymentType]="order.chosenPaymentType"
        [cashReturn]="order.cashReturn"
        [paymentImage]="order.paymentImage">
    </app-chosen-payment-method>

    <app-payment-link *ngIf="order && order.paymentLink"
        [currentPaymentLink]="order.paymentLink">
    </app-payment-link>

    <app-menu-summary *ngIf="menuOrderDishesListRef"
        itemLabel="labels.dishes"
        [items]="menuOrderDishesListRef.summary.total"
        [quantity]="menuOrderDishesListRef.summary.quantity"
        [tip]="menuOrderDishesListRef.summary.tip"
        [additionalCharges]="order.additionalCharges"
        [promoCodeEntity]="order.issuedPromoCode"
        [tipPercentage]="order.tippingPercentage">
    </app-menu-summary>

    <app-chosen-service-type *ngIf="order && order.serviceType"
        [chosenServiceType]="order.serviceType"
        [chosenLocation]="order.choosenLocation">
    </app-chosen-service-type>
    <app-reports-comments-view *ngIf="order" [entityId]="order.id"></app-reports-comments-view>
    <app-reports-logs-view *ngIf="order" [logs]="order.changelog"></app-reports-logs-view>
</div>

