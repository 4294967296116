import { Component, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AppSettings } from '../../../app.settings';
import { HttpService } from '../../../service/http.service';
import { ImageService } from '../../../service/image.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../../../auth/auth.serviceV2';
import { MatDialog } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { TableDishesListComponent } from '../components/table-dishes-list/table-dishes-list.component';
import { SentRequestDialogComponent } from '../../../components/sent-request-dialog/sent-request-dialog.component';
import { BaseClientViewComponent } from '../../baseclientview.component';
import { MenuRequestStatus } from '../../../models/menu-request-status-enum';
import { RouteConstants } from '../../../app.constants';
import { isValidCode, encodeUUID } from '../../../util/encode';
import { environment } from '../../../../environments/environment';
import { QrReaderDialogComponent } from '../../../components/qr-reader-dialog/qr-reader-dialog.component';
import { YesNoDialogComponent } from '../../../components/yes-no-dialog/yes-no-dialog.component';
import { CanDeleteDialogComponent } from '../../../components/can-delete-dialog/can-delete-dialog.component';
import { CommentsPreviewComponent } from '../../../components/comments-preview/comments-preview.component';
import { EntityType } from '../../../models/entity-type-enum';
import {
  bankTransferImageValidator,
  cashReturnValidator,
  chosenPaymentTypeValidator,
  tableNumberValidator,
} from '../../../util/form-utils';
import { PaymentType } from '../../../models/payment-type-enum';
import { firstValueFrom } from 'rxjs';
import { isEmpty } from 'lodash';
import AdditionalChargesUtil from '../../../util/additionalChargesUtil';
import {
  setActivityPageHead,
  setDefaultPageHead,
} from '../../../util/seo-utils';
import { Meta, Title } from '@angular/platform-browser';
import { AzulPaymentFormComponent } from '../../../components/azul-payment-form/azul-payment-form.component';
import {
  ActivityMenuEntity,
  CreateMenuRequestCommand,
  Dish,
  DishOption,
  MenuRequestEntity,
  MenuSection,
} from '../../../models/activity-menu';
import { HubEntity } from '../../../models/hub';
import QCVUtil from '../../../util/questionsClientViewUtil';
import { UserAdditionalInfoService } from '../../authentication/login/user-additional-info.service';
import { MenuServiceType } from '../../../models/menu-service-type-enum';
import { MatureContentService } from '../../../service/mature-content.service';
import { HubService } from '../../../service/hub.service';

@Component({
  selector: 'app-activity-menu-client-view',
  templateUrl: './activity-menu-client-view.component.html',
  styleUrls: ['./activity-menu-client-view.component.scss'],
})
export class ActivityMenuClientViewComponent
  extends BaseClientViewComponent
  implements OnInit
{
  @ViewChild('commentsPreview') commentsPreview: CommentsPreviewComponent;
  @ViewChild('requestListTag') requestList;
  @ViewChild('tableDishesList') tableDishesListRef: TableDishesListComponent;
  @ViewChild('tabs', { static: false }) tabs: MatTabGroup;
  @ViewChild('azulPaymentEl') azulPayment: AzulPaymentFormComponent;

  readonly activityType = 'menu';
  coordinates = null;
  entity: ActivityMenuEntity;
  sendRequestPin;
  qrCode = null;
  table = 0;
  type = EntityType.ACTIVITY_MENU;
  reported = false;
  private scrolls = [0, 0, 0];
  private mainContent;
  filteredDishes = [];
  sections = [];
  searchTerm = '';
  selectedSection;

  constructor(
    private authService: AuthService,
    private userAdditionalInfoService: UserAdditionalInfoService,
    private httpService: HttpService,
    public appSettings: AppSettings,
    public router: Router,
    public snackBar: MatSnackBar,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private fb: UntypedFormBuilder,
    protected imageService: ImageService,
    protected translate: TranslateService,
    protected location: Location,
    private matureContentService: MatureContentService,
    private titleService: Title,
    private metaTagService: Meta,
    private hubService: HubService
  ) {
    super(appSettings, snackBar, imageService, router, location, translate);
  }

  get tableNumber(): UntypedFormControl {
    return this.form.get('tableNumber') as UntypedFormControl;
  }

  get fullQrCode() {
    let baseURL = environment.appUrl;
    if (!this.handler || !this.hubId || !this.activityId) {
      return false;
    }
    if (location.hostname === 'localhost') {
      baseURL = 'https://localhost:4200';
    }
    return `${baseURL}/su/a/${this.activityId}?code=${encodeUUID(
      this.activityId
    )}`;
  }

  async ngOnInit() {
    await this.authService.isAuthenticated();
    this.user = this.authService.getUserProfile();
    this.hubId = this.route.snapshot.queryParams['hubId'];
    this.categoryId = this.route.snapshot.queryParams['categoryId'];
    this.activityId = this.route.snapshot.queryParams['activityId'];
    this.qrCode = this.route.snapshot.queryParams['code'];
    this.table = this.route.snapshot.queryParams["table"] ? Number(this.route.snapshot.queryParams["table"]) : 0;
    this.mainContent = document.getElementById('main-content');
    this.settings.loadingSpinner = true;
    this.showPaymentResult(this.route.snapshot.queryParams['status']);
    this.handler = this.route.snapshot.paramMap.get('handler');
    localStorage.removeItem('request_return_url');
    await this.retrieveHub(this.hubId);
    this.hubService.setHub(this.hub);
    this.matureContentService.check(this.hub.adultContent);
    this.initPage();
  }

  private async initPage() {
    try {
      const activityUrl = `${this.apiUrl}/activity-menu/v1/${this.activityId}`;

      const activityResponse: ActivityMenuEntity = await firstValueFrom(
        this.httpService.doGet(activityUrl)
      );
      if (activityResponse != null) {
        this.entity = activityResponse;
        this.filteredDishes = this.entity.sectionList;
        this.sections = this.filteredDishes.map(
          (section: MenuSection) => section.sectionName
        );
        setActivityPageHead(
          this.titleService,
          this.metaTagService,
          activityResponse,
          this.hub
        );
      } else {
        setDefaultPageHead(this.titleService, this.metaTagService, true);
      }
      await this.createForm();

      if (
        this.route.snapshot.fragment === 'address' &&
        (await this.authService.isAuthenticated())
      ) {
        window.location.hash = '';
        if (this.sendRequestPin) {
          clearTimeout(this.sendRequestPin);
        }
        setTimeout(() => {
          this.settings.loadingSpinner = true;
        }, 500);
        this.sendRequestPin = setTimeout(() => {
          const data = localStorage.getItem(this.entity.id);
          if (data) {
            const parsedData = JSON.parse(data);
            this.goToTable();
            this.sendRequest({
              ...parsedData,
              clientId: this.user.userId,
            });
          } else {
            this.settings.loadingSpinner = false;
          }
        }, 5000);
      } else {
        setTimeout(() => {
          this.settings.loadingSpinner = false;
        }, 1000);
      }

      if (
        this.route.snapshot.fragment === 'request' &&
        (await this.authService.isAuthenticated())
      ) {
        window.location.hash = '';
        if (this.sendRequestPin) {
          clearTimeout(this.sendRequestPin);
        }
        setTimeout(() => {
          this.settings.loadingSpinner = true;
        }, 500);
        this.sendRequestPin = setTimeout(() => {
          const data = localStorage.getItem(this.entity.id);
          if (data) {
            const parsedData = JSON.parse(data);
            this.sendRequest({
              ...parsedData,
              clientId: this.user.userId,
            });
          } else {
            this.settings.loadingSpinner = false;
          }
        }, 5000);
      } else {
        setTimeout(() => {
          this.settings.loadingSpinner = false;
        }, 1000);
      }
    } catch (error) {
      setDefaultPageHead(this.titleService, this.metaTagService, true);
      console.log('Error getting activity: ', error);
      if (error.status === 404) {
        this.notFound = true;
      }
      this.settings.loadingSpinner = false;
    }
  }

  private async retrieveHub(hubId: string) {
    try {
      const hubUrl = `${this.apiUrl}/hubs/v1/${hubId}`;
      const hubResponse: HubEntity = await firstValueFrom(
        this.httpService.doGet(hubUrl)
      );
      if (!hubResponse) return;
      this.hub = hubResponse;
    } catch (error) {
      console.log('Error getting activity: ', error);
    }
  }

  private async createForm() {
    if (!this.entity) {
      return;
    }

    this.form = this.fb.group(
      {
        hubId: [this.hubId],
        activityId: [this.activityId],
        clientId: [this.user?.userId],
        status: [MenuRequestStatus.OPEN],
        questionAnswerMap: this.fb.group({}),
        chosenPaymentType: [''],
        cashReturn: [null],
        paymentImage: [''],
        totalPrice: [0],
        additionalIndications: [''],
        tableNumber: [this.table],
        serviceType: [
          !this.entity.serviceTypes || this.entity.serviceTypes.length === 0 || this.table > 0
            ? MenuServiceType.RESTAURANT
            : null,
          [Validators.required],
        ],
        choosenLocation: [null],
        promoCode: [''],
        promoCodeApplied: [false],
      },
      {
        validators: [
          bankTransferImageValidator,
          cashReturnValidator,
          chosenPaymentTypeValidator(this.hub.supportedPaymentTypes),
          tableNumberValidator(this.entity.tableRequired),
        ],
      }
    );

    if (this.table > 0) {
      this.form.get('serviceType').disable();
      this.form.get('tableNumber').disable();
    }
  }

  get serviceType() {
    return this.form.get('serviceType') as UntypedFormControl;
  }

  get choosenLocation() {
    return this.form.get('choosenLocation') as UntypedFormControl;
  }

  get chosenPaymentType() {
    return this.form.get('chosenPaymentType') as UntypedFormControl;
  }

  get totalPrice() {
    return this.form.get('totalPrice') as UntypedFormControl;
  }

  get questionAnswerMap() {
    return this.form.get('questionAnswerMap') as UntypedFormGroup;
  }

  getCurrentPosition(options = {}) {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject, options);
    });
  }

  getDishRequestedList() {
    return this.tableDishesListRef.getDishRequestedList();
  }

  private getRequestBody(body) {
    if (body) {
      return body;
    }
    let rawValues = this.form.getRawValue();
    rawValues.questionAnswerMap = {};
    rawValues['totalPrice'] =
      Number(rawValues['totalPrice']) > 0
        ? Number(rawValues['totalPrice']).toFixed(2)
        : '0';
    rawValues['tippingPercentage'] = Number(
      this.tableDishesListRef.form.get('tip').value
    );
    rawValues['dishRequestedList'] = this.getDishRequestedList();
    if (!rawValues.chosenPaymentType) {
      rawValues.chosenPaymentType = PaymentType.CASH;
    }
    rawValues = AdditionalChargesUtil.creanPaymentContent(rawValues);
    return rawValues;
  }

  async sendRequest(body?: any) {
    if (
      !this.entity ||
      !this.form ||
      !this.tableDishesListRef ||
      this.tableDishesListRef.summary.quantity <= 0 ||
      (this.serviceType.value === MenuServiceType.DELIVERY &&
        !this.choosenLocation)
    ) {
      this.settings.loadingSpinner = false;
      return;
    }

    if (!(await this.authService.isAuthenticated())) {
      this.tableDishesListRef.setTip();
      localStorage.setItem('return_url', `${this.router.url}#request`);
      localStorage.setItem('request_return_url', `${this.router.url}#request`);
      localStorage.setItem(
        this.entity.id,
        JSON.stringify(this.getRequestBody(body))
      );
      await this.router.navigate([RouteConstants.auth]);
      this.settings.loadingSpinner = false;
      return;
    }

    this.settings.loadingSpinner = true;

    if (
      !(await this.userAdditionalInfoService.check(
        `${this.router.url}#request`
      ))
    ) {
      localStorage.setItem(
        this.entity.id,
        JSON.stringify(this.getRequestBody(body))
      );
      this.settings.loadingSpinner = false;
      return;
    }

    if (
      this.entity.qrCodeScanRequired &&
      this.serviceType.value === MenuServiceType.RESTAURANT &&
      (!this.qrCode || !isValidCode(this.activityId, this.qrCode))
    ) {
      this.tableDishesListRef.setTip();
      this.canShowQRReader();
      this.settings.loadingSpinner = false;
      return;
    }

    const url = `${this.apiUrl}/activity-menu/v1/menu-request`;

    const rawValues = this.getRequestBody(body);

    const {
      clientId,
      hubId,
      activityId,
      questionAnswerMap,
      location,
      totalPrice,
      clientPhoneNumber,
      chosenPaymentType,
      cashReturn,
      paymentImage,
      status,
      dishRequestedList,
      tippingPercentage,
      tableNumber,
      additionalIndications,
      serviceType,
      choosenLocation,
      promoCode,
      promoCodeApplied,
    } = rawValues;

    const requestBody: CreateMenuRequestCommand = {
      clientId,
      hubId,
      activityId,
      questionAnswerMap: QCVUtil.transformAnswersV1ToV2(questionAnswerMap),
      location,
      totalPrice,
      clientPhoneNumber,
      chosenPaymentType,
      cashReturn,
      paymentImage,
      status,
      dishRequestedList,
      tippingPercentage,
      additionalIndications,
      serviceType,
      choosenLocation,
      ...(serviceType === MenuServiceType.RESTAURANT && { tableNumber }),
      ...(promoCodeApplied && { promoCode }),
    };
    try {
      const response: MenuRequestEntity = await firstValueFrom(
        this.httpService.doPost(url, requestBody)
      );
      this.locked = true;
      this.tableDishesListRef.clearLocal();
      this.form.reset();
      this.createForm();
      if (rawValues.chosenPaymentType === PaymentType.TINYALL_PAY) {
        this.azulPayment?.azulPay(response);
        return;
      }
      this.openDialog();
    } catch (error) {
      this.showSnackBar('Error Processing Your Request', 'OK', 2000);
    } finally {
      this.locked = false;
      this.settings.loadingSpinner = false;
    }
  }

  async onCancel() {}

  goToDishes() {
    const tabGroup = this.tabs;
    if (!tabGroup || !(tabGroup instanceof MatTabGroup)) {
      return;
    }
    this.saveScroll(tabGroup.selectedIndex);
    tabGroup.selectedIndex = 0;
    this.scrollToSaved(0);
  }

  goToTable() {
    const tabGroup = this.tabs;
    if (!tabGroup || !(tabGroup instanceof MatTabGroup)) {
      return;
    }
    this.saveScroll(tabGroup.selectedIndex);
    tabGroup.selectedIndex = 1;
    this.scrollToSaved(1);
  }

  goToCheckout() {
    const tabGroup = this.tabs;
    if (!tabGroup || !(tabGroup instanceof MatTabGroup)) {
      return;
    }
    this.saveScroll(tabGroup.selectedIndex);
    tabGroup.selectedIndex = 2;
    this.scrollToSaved(2);
  }

  saveScroll(index: number) {
    if (this.mainContent) {
      this.scrolls[index] = this.mainContent.scrollTop;
    }
  }

  scrollToSaved(index: number) {
    if (this.mainContent) {
      setTimeout(() => {
        this.mainContent.scrollTop = this.scrolls[index];
      }, 200);
    }
  }

  updateTable() {
    if (this.tableDishesListRef) {
      this.tableDishesListRef.search().then();
      this.scrolls[1] = 0;
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(SentRequestDialogComponent, {
      minWidth: '240px',
      data: {
        title: 'titles.order',
        content: 'messages.operation_successful',
        hasRichText: !!this.entity.newRequestMessage,
        richContent: this.entity.newRequestMessage,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.requestList.search();
      this.goToDishes();
      setTimeout(() => {
        const element = document.getElementById('request-list');
        if (element !== null) {
          element.scrollIntoView();
        }
      }, 300);
    });
  }

  openQrReader(): void {
    const dialogRef = this.dialog.open(QrReaderDialogComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'qr-reader-dialog',
      data: this.fullQrCode || null,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.router.navigateByUrl(`${result}`);
        const splattedResult = result.split('=');
        this.qrCode = splattedResult[splattedResult.length - 1];
        this.sendRequest();
      }
    });
  }

  canClearTable(): void {
    const dialogRef = this.dialog.open(CanDeleteDialogComponent, {
      minWidth: '240px',
      data: {
        title: this.translate.instant('titles.crear_table'),
        content: this.translate.instant('labels.clear_table'),
        cancelLabel: this.translate.instant('buttons.no'),
        okLabel: this.translate.instant('buttons.yes'),
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.tableDishesListRef.clearLocal();
        this.goToDishes();
      }
    });
  }

  canShowQRReader(): void {
    const dialogRef = this.dialog.open(YesNoDialogComponent, {
      minWidth: '240px',
      data: {
        title: 'titles.alert',
        content: 'messages.qr_scanning_required',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.openQrReader();
      }
    });
  }

  getQuestionsLength(): number {
    if (!this.form || !this.form.get('questionAnswerMap')) {
      return 0;
    }
    const GROUP = this.form.get('questionAnswerMap') as UntypedFormGroup;
    return Object.keys(GROUP.controls).length;
  }

  get showPriceSummary() {
    return (
      !this.locked &&
      this.entity &&
      this.form &&
      this.tableDishesListRef &&
      (this.getQuestionsLength() > 0 ||
        !isEmpty(this.entity.additionalCharges || {}) ||
        this.tableDishesListRef.summary.total)
    );
  }

  get showPaymentMethods() {
    return (
      !this.locked &&
      this.form &&
      this.serviceType.value &&
      this.hub &&
      this.hub.supportedPaymentTypes &&
      this.hub.supportedPaymentTypes.length > 0 &&
      this.totalPrice.value
    );
  }

  get showSendRequest() {
    return (
      !this.locked &&
      this.form &&
      (this.chosenPaymentType.value !== PaymentType.TINYALL_PAY ||
        !this.totalPrice.value)
    );
  }

  get disableSendRequest() {
    const a =
      !this.form ||
      this.form.invalid ||
      (this.serviceType.value === MenuServiceType.DELIVERY &&
        !this.choosenLocation.value);
    return a;
  }

  onSelectSection(section?: string): void {
    this.selectedSection = section;
    this.onSearch(this.searchTerm);
  }

  onSearch(term?: string): void {
    this.searchTerm = term;
    if (!this.searchTerm && !this.selectedSection) {
      this.filteredDishes = this.entity.sectionList;
      return;
    }

    let preFilteredSections = this.entity.sectionList;

    if (this.selectedSection) {
      preFilteredSections = preFilteredSections.filter(
        (section: MenuSection) => section.sectionName == this.selectedSection
      );
    }

    if (!this.searchTerm) {
      this.filteredDishes = preFilteredSections;
      return;
    }

    const localTerms = this.searchTerm.toLowerCase();

    const sections = preFilteredSections.map((section: MenuSection) => {
      const dishes = section.dishes.filter((dish: Dish) => {
        return (
          dish.name.toLowerCase().includes(localTerms) ||
          dish.dishOptions.some((option: DishOption) => {
            return option.description.toLowerCase().includes(localTerms);
          })
        );
      });

      return {
        ...section,
        dishes: dishes,
      } as MenuSection;
    });

    this.filteredDishes = sections.filter(
      (section: MenuSection) => section.dishes.length > 0
    );
  }

  clearFilters() {
    this.selectedSection = "";
    this.onSearch("");
  }
}
