import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { DishItemClientViewComponent } from '../dish-item-client-view/dish-item-client-view.component';
import { Dish } from '../../../../models/activity-menu';

@Component({
  selector: 'app-dish-list-client-view',
  templateUrl: './dish-list-client-view.component.html',
  styleUrls: ['./dish-list-client-view.component.scss'],
})
export class DishListClientViewComponent implements OnInit {
  @Input() dishes: any[];
  @Output() onAddToTable = new EventEmitter();
  @ViewChildren(DishItemClientViewComponent)
  dishItems: QueryList<DishItemClientViewComponent>;

  constructor() {}

  ngOnInit(): void {}

  addToTable(data) {
    this.onAddToTable.emit(data);
  }
  public closeAll() {
    this.dishItems.forEach((d) => d.close());
  }

  trackByItemId(_index: number, item: Dish) {
    return item.name;
  }
}
