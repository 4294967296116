
<ng-container *ngIf="!notFound; else pageNotFound">
<mat-tab-group #tabs class="header-less-tabs">
    <mat-tab>
        <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
            <button (click)="back()" mat-button><span translate>tinyAll.back</span></button>
            <app-report-abuse-button *ngIf="!reported" [user]="user" [entityId]="activityId" [entityType]="type" [entityName]="entity?.name" [entity]="entity" (reported)="reported = true"></app-report-abuse-button>
        </div>
        
        <div class="page-container overflow-visible" fxLayout="row wrap" fxLayoutAlign="center start">
            <div class="flex-p" fxFlex="100">
                <app-navigation-menu *ngIf="hub" [hubId]="hub?.id" [handler]="hub?.urlHandler">
                </app-navigation-menu>
            </div>
            <div class="flex-p" fxFlex="100">
                <mat-card class="card-container">
                    <mat-card-header>
                        <mat-card-title class="activity-title">{{entity?.name}}</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <app-swiper-view *ngIf="entity" 
                            [images]="entity.images"
                            [canPlay]="true"
                            [canLink]="true">
                        </app-swiper-view>
                    </mat-card-content>
                </mat-card>
            </div>

            <div class="flex-p" fxFlex="100">
                <mat-card class="preview-card custom-card">
                    <app-comments-preview #commentsPreview *ngIf="entity" entityType="ACTIVITY_MENU" [entityName]="entity.name" [entityId]="entity.id"></app-comments-preview>
                </mat-card>
            </div>
    
            <app-collapsible-description
                [text]="entity?.infoText"
                [collapsible]="true"
                [expandedByDefault]="false"
                title="titles.how_to_order"
            >
            </app-collapsible-description>

            <app-menu-order-list-client-view *ngIf="entity && user" 
                #requestListTag 
                id="request-list"
                class="request-list"
                [activityId]="activityId"
                [activityType]="activityType"
                [hubId]="hubId"
                [user]="user"
                [open]="true"
                [title]="true"
                [footer]="true"
                (cancel)="onCancel()">
            </app-menu-order-list-client-view>
            
            <mat-divider *ngIf="requestList && (requestList.loading || !requestList.open || requestList.anyOpen)"></mat-divider>

            <app-dish-sections-bar class="w-full sticky z-30" [selectedSection]="selectedSection" [sections]="sections" (onChange)="onSelectSection($event)"></app-dish-sections-bar>

            <div class="rounded shadow bg-white p-4 w-full m-2">
                <app-dish-search-autocomplete class="w-full" [searchTerm]="searchTerm" (onChange)="onSearch($event)"></app-dish-search-autocomplete>
            </div>

            <app-menu-section-list-client-view *ngIf="entity && activityId && hubId && filteredDishes"
                #productList
                [sectionList]="filteredDishes"
                [activityId]="activityId"
                (onAddToTable)="updateTable()" (onClear)="clearFilters()">
            </app-menu-section-list-client-view>

            <app-company-info-card
                *ngIf="hub"
                class="footer"
                [name]="hub.name"
                [address]="hub.address"
                [phone]="hub.phoneNumber"
                [email]="hub.email"
                [handler]="hub.urlHandler"
                [image]="hub.icon"
            >
                <app-social-card-actions 
                    *ngIf="entity && commentsPreview" 
                    [title]="entity.name"
                    [description]="entity.infoText"
                    [images]="entity.images"
                    [preview]="commentsPreview"
                    [views]="entity.views"
                    [requests]="entity.requestCount"
                    [id]="activityId"
                    [createdOn]="entity.createdOn"
                >
                </app-social-card-actions>
            </app-company-info-card>
        
            <button class="speed-dial-action-button" 
                *ngIf="tableDishesListRef && tableDishesListRef.summary.quantity > 0"
                (click)="goToTable()"  
                mat-fab 
                color="primary">
                <span class="counter">{{tableDishesListRef.summary.quantity}}</span>&nbsp;
                <mat-icon>restaurant</mat-icon>
            </button>
        </div>
    </mat-tab>

    <mat-tab>
        <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
            <button (click)="goToDishes()" mat-button><span translate>tinyAll.back</span></button>
            <h2 class="title" translate>titles.order</h2>
        </div>

        <div class="page-container" fxLayout="row wrap" fxLayoutAlign="center start">

            <mat-card class="clear-table" *ngIf="tableDishesListRef && tableDishesListRef.items && tableDishesListRef.items.length > 0">
                <div fxLayout="row nowrap" fxLayoutAlign="center space-between" class="gap-2 items-center">
                    <span translate>labels.clear_table</span>
                    <button mat-flat-button (click)="canClearTable()" >
                        <span translate>buttons.clear</span>
                    </button>
                </div>
            </mat-card>

            <app-table-dishes-list *ngIf="entity && activityId && hubId"
                #tableDishesList
                [activityId]="activityId"
                [hubId]="hubId">
            </app-table-dishes-list>

            <form *ngIf="form && entity && entity.acceptOrder" novalidate [formGroup]="form" fxLayout="column" fxLayoutAlign="center" class="menu-order-form">
                <mat-label class="text-lg text-primary mb-1" translate>formFields.additional_indications</mat-label>
                <mat-form-field appearance="fill">
                    <textarea rows="4" matInput matTextareaAutosize formControlName="additionalIndications"></textarea>
                </mat-form-field>

                <app-select-service-type [form]="form" [activityMenu]="entity"></app-select-service-type>
            </form>

            <app-questions-summary *ngIf="showPriceSummary && form && hub && entity"
                itemLabel="labels.dishes"
                [entityId]="entity.id"
                [hubId]="hub?.id"
                [items]="tableDishesListRef.summary.total"
                [quantity]="tableDishesListRef.summary.quantity"
                [questionAnswerMap]="questionAnswerMap"
                [totalPrice]="totalPrice"
                [additionalCharges]="entity.additionalCharges"
                [tipForm]="tableDishesListRef.form"
                [promoCodeForm]="form"
            >
            </app-questions-summary>

            <app-select-payment-method
                *ngIf="showPaymentMethods" 
                [form]="form"
                [hub]="hub"
                [isMenu]="true"
                [totalPrice]="totalPrice"
                [disabled]="disableSendRequest"
                (sendRequest)="sendRequest()"
            >
            </app-select-payment-method>
            <app-azul-payment-form #azulPaymentEl></app-azul-payment-form>
            <div fxLayout="row" fxFlex="100" fxLayoutAlign="end center">
                <button class="speed-dial-action-button-fix" 
                    *ngIf="showSendRequest"
                    [disabled]="disableSendRequest"
                    (click)="sendRequest()" 
                    mat-fab color="accent">
                    <span translate>productList.place_order</span>&nbsp;<mat-icon>local_atm</mat-icon>
                </button>
            </div>
            <app-company-info-card
                *ngIf="showSendRequest && hub"
                [name]="hub.name"
                [address]="hub.address"
                [phone]="hub.phoneNumber"
                [email]="hub.email"
                [handler]="hub.urlHandler"
                [image]="hub.icon"
            >
                <app-social-card-actions 
                    *ngIf="entity && commentsPreview" 
                    [title]="entity.name"
                    [description]="entity.infoText"
                    [images]="entity.images"
                    [preview]="commentsPreview"
                    [views]="entity.views"
                    [requests]="entity.requestCount"
                    [id]="activityId"
                    [createdOn]="entity.createdOn"
                >
                </app-social-card-actions>
            </app-company-info-card>
        </div>
    </mat-tab>
</mat-tab-group>
</ng-container>
<ng-template #pageNotFound>
    <app-page-not-found (back)="back()"></app-page-not-found>
</ng-template>
<app-loading></app-loading>
