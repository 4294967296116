<div fxLayout="row">
  <h2 class="title" translate>titles.sections</h2>
</div>
<div class="list-container">
  <div fxLayout="column" fxLayoutGap="20px">
    <mat-accordion *ngIf="sectionList.length > 0; else noItems" [multi]="true">
      <div class="flex-p" *ngFor="let item of sectionList; let i = index; trackBy: trackByItemId">
        <mat-card [id]="'section' + i" class="section-container">
          <mat-card-content>
            <mat-expansion-panel
              expanded="'true'"
              (opened)="onSectionOpen('section' + i)"
              (closed)="dishesView.closeAll()"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ item.sectionName }}
                </mat-panel-title>
              </mat-expansion-panel-header>

              <mat-card-header class="header">
                <mat-card-title>{{ item.sectionName }}</mat-card-title>
                <mat-card-subtitle class="description">{{
                  item.sectionDescription
                }}</mat-card-subtitle>
              </mat-card-header>

              <app-dish-list-client-view
                #dishesView
                [dishes]="item.dishes"
                (onAddToTable)="addToTable($event, item)"
              ></app-dish-list-client-view>
            </mat-expansion-panel>
          </mat-card-content>
        </mat-card>
      </div>
    </mat-accordion>
  </div>
  <ng-template #noItems>
    <div
      class="pt-7 text-center rounded shadow bg-white p-4 m-2 w-[calc(100%-16px)]"
    >
      <mat-icon class="text-[80px] !w-auto !h-auto text-gray-400"
        >no_meals</mat-icon
      >
      <p class="text-lg text-gray-500 font-medium m-3.5 p-4" translate>
        messages.empty_dishes_text
      </p>
      <button
        (click)="onClear.emit()"
        class="bg-accent text-white px-4 py-2 rounded-full mx-1 cursor-pointer mb-4"
        translate
      >
        buttons.clear_filters
      </button>
    </div>
  </ng-template>
</div>
