import {
  Component,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  HostListener,
  AfterViewInit,
} from '@angular/core';

@Component({
  selector: 'app-dish-sections-bar',
  templateUrl: './dish-sections-bar.component.html',
})
export class DishSectionsBarComponent implements AfterViewInit {
  @ViewChild('sectionContainer') sectionContainer!: ElementRef;
  @ViewChild('sectionList') sectionList!: ElementRef;

  showLeftArrow = false;
  showRightArrow = false;

  @Input() sections = [];
  @Input() selectedSection: string | null;
  @Output() onChange = new EventEmitter();

  ngAfterViewInit() {
    setTimeout(() => {
      this.updateArrowsVisibility();
    }, 1000);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.updateArrowsVisibility();
  }

  onScroll(event) {
    event.stopPropagation();
    this.updateArrowsVisibility();
  }

  updateArrowsVisibility() {
    if (!this.sectionContainer) return;
    const container = this.sectionContainer.nativeElement;
    const listElement = this.sectionList.nativeElement;
    this.showLeftArrow = container.scrollLeft > 0;
    this.showRightArrow =
      listElement.clientWidth > container.scrollLeft + container.clientWidth;
  }

  scrollLeft() {
    if (this.sectionContainer) {
      //this.sectionContainer.nativeElement.scrollLeft -= 200; // Adjust scroll amount
      this.sectionContainer.nativeElement.scrollBy(-200, 0);
      this.updateArrowsVisibility();
    }
  }

  scrollRight() {
    if (this.sectionContainer) {
      //this.sectionContainer.nativeElement.scrollLeft += 200; // Adjust scroll amount
      this.sectionContainer.nativeElement.scrollBy(200, 0);
      this.updateArrowsVisibility();
    }
  }

  selectSection(value: string) {
    if (this.selectedSection == value) {
      this.onChange.emit(null);
    } else {
      this.onChange.emit(value);
    }
  }
}
