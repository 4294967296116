import { Injectable } from '@angular/core';
import { HubEntity } from '../models/hub';

@Injectable({
  providedIn: 'root',
})
export class HubService {
  public hub?: HubEntity;

  setHub(value?: HubEntity) {
    this.hub = value;
  }
}
