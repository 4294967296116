import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import {
  faShoppingBag,
  faBiking,
  faUtensils,
} from '@fortawesome/free-solid-svg-icons';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as i18nIsoCountries from 'i18n-iso-countries';
import { AppSettings } from '../../../../app.settings';
import { ActivityMenuEntity } from '../../../../models/activity-menu';
import { MenuServiceType } from '../../../../models/menu-service-type-enum';
import { AddressListDialogComponent } from '../../../../components/address-list-dialog/address-list-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../../../auth/auth.serviceV2';
import { AddressFormDialogComponent } from '../../../../components/address-form-dialog/address-form-dialog.component';
import { AddressAction } from '../../../../models/address-action-enum';
import { UserEntity } from '../../../../models/user';
import { UserService } from '../../../../auth/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteConstants } from '../../../../app.constants';

@Component({
  selector: 'app-select-service-type',
  templateUrl: './select-service-type.component.html',
  styleUrls: ['./select-service-type.component.scss'],
})
export class SelectServiceTypeComponent {
  @Input() form: UntypedFormGroup;
  @Input() activityMenu: ActivityMenuEntity;
  @Output() sendRequest: EventEmitter<any> = new EventEmitter();

  readonly faUtensils = faUtensils;
  readonly faShoppingBag = faShoppingBag;
  readonly faBiking = faBiking;

  settings;
  selectedLocationIndex;
  user;

  constructor(
    public dialog: MatDialog,
    public appSettings: AppSettings,
    private translate: TranslateService,
    private authService: AuthService,
    private userService: UserService,
    protected snackBar: MatSnackBar,
    public router: Router,
    private route: ActivatedRoute
  ) {
    this.settings = this.appSettings.settings;
    this.user = this.authService.getUserProfile();
  }

  get MenuServiceType() {
    return MenuServiceType;
  }

  get selectedPaymentType() {
    return this.form.get('chosenPaymentType') as UntypedFormControl;
  }

  get selectedServiceType() {
    return this.form.get('serviceType') as UntypedFormControl;
  }

  get choosenLocation() {
    return this.form.get('choosenLocation') as UntypedFormControl;
  }

  get choosenLocationValue() {
    return (this.form.get('choosenLocation') as UntypedFormControl).value;
  }

  get tableNumber(): UntypedFormControl {
    return this.form.get('tableNumber') as UntypedFormControl;
  }

  getCountryName(country: string) {
    return i18nIsoCountries.getName(country, this.translate.getDefaultLang());
  }

  showSnackBar(message, action, duration, panelClass = '') {
    this.snackBar.open(message, action, {
      duration: duration,
      panelClass,
    });
  }

  isServiceTypeSupported(serviceType: MenuServiceType): boolean {
    if (!this.activityMenu || !this.activityMenu.serviceTypes) return false;
    return this.activityMenu.serviceTypes.includes(serviceType);
  }

  get show() {
    return (
      this.activityMenu &&
      this.activityMenu.serviceTypes &&
      this.activityMenu.serviceTypes.length > 0
    );
  }

  openAddressPicker() {
    const dialogRef = this.dialog.open(AddressListDialogComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'address-list-dialog',
      data: {
        selectedLocationIndex: this.selectedLocationIndex,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const { index, location } = result;
        this.choosenLocation.setValue(location);
        this.selectedLocationIndex = index;
      }
    });
  }

  changeServiceType({ value }) {
    if (
      value === MenuServiceType.DELIVERY &&
      this.user &&
      this.user.locations &&
      this.user.locations.length > 0
    ) {
      const index = this.user.locations.findIndex(
        ({ defaultLocation }) => defaultLocation
      );
      if (index !== -1) {
        this.choosenLocation.setValue(this.user.locations[index]);
        this.selectedLocationIndex = index;
      } else {
        this.choosenLocation.setValue(this.user.locations[0]);
        this.selectedLocationIndex = 0;
      }
    } else {
      this.choosenLocation.setValue(null);
      this.selectedLocationIndex = undefined;
    }

    if (this.selectedPaymentType) {
      this.selectedPaymentType.setValue('');
    }
  }

  async openAddressForm() {
    if (!(await this.authService.isAuthenticated())) {
      localStorage.setItem('return_url', `${this.router.url}#address`);
      localStorage.setItem('request_return_url', `${this.router.url}#address`);
      await this.router.navigate([RouteConstants.auth]);
      return;
    }

    if (!this.user) return;
    const dialogRef = this.dialog.open(AddressFormDialogComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'address-form-dialog',
      data: {
        user: this.user,
      },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.settings.loadingSpinner = true;
        const { locationData, action } = result;
        let currentLocations = this.user.locations || [];
        if (locationData && locationData.defaultLocation) {
          currentLocations = currentLocations.map((current) => ({
            ...current,
            defaultLocation: false,
          }));
        }
        let newLocations;
        switch (action) {
          case AddressAction.CREATE:
            newLocations = [...currentLocations, locationData];
            break;
        }

        const token = this.authService.getIdToken();
        const user: UserEntity = await this.userService.updateUser(token, {
          ...this.user,
          locations: newLocations,
        });
        localStorage.setItem('user_profile', JSON.stringify(user));
        this.user = user;
        this.choosenLocation.setValue(user.locations[0]);
        this.selectedLocationIndex = 0;
        this.settings.loadingSpinner = false;
      }
    });
  }
}
