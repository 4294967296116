
<ng-container *ngIf="!notFound; else pageNotFound">
<mat-tab-group #tabs class="header-less-tabs">
    <mat-tab>
        <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
            <button (click)="back()" mat-button><span translate>tinyAll.back</span></button>
            <app-report-abuse-button *ngIf="!reported" [user]="user" [entityId]="activityId" [entityType]="type" [entityName]="entity?.name" [entity]="entity" (reported)="reported = true"></app-report-abuse-button>
        </div>
        
        <div class="page-container" fxLayout="row wrap" fxLayoutAlign="center start">
            <div class="flex-p" fxFlex="100">
                <app-navigation-menu *ngIf="hub" [hubId]="hub?.id" [handler]="hub?.urlHandler">
                </app-navigation-menu>
            </div>
            <div class="flex-p" fxFlex="100">
                <mat-card class="card-container">
                    <mat-card-header>
                        <mat-card-title class="activity-title">{{entity?.name}}</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <app-swiper-view *ngIf="entity" 
                            [images]="entity.images"
                            [canPlay]="true"
                            [canLink]="true">
                        </app-swiper-view>
                    </mat-card-content>
                </mat-card>
            </div>

            <div class="flex-p" fxFlex="100">
                <mat-card class="preview-card custom-card">
                    <app-comments-preview #commentsPreview *ngIf="entity" entityType="ACTIVITY_PRODUCT_LISTING" [entityName]="entity.name" [entityId]="entity.id"></app-comments-preview>
                </mat-card>
            </div>
    
            <app-collapsible-description [text]="entity?.infoText" [collapsible]="true"></app-collapsible-description>

            <app-address-selector *ngIf="form && entity?.addressRequired && formIsReady" [form]="form"></app-address-selector>

            <app-order-list-client-view *ngIf="entity && user" 
                #requestListTag 
                id="request-list"
                class="request-list"
                [activityId]="activityId"
                [activityType]="activityType"
                [hubId]="hubId"
                [user]="user"
                [size]="5"
                [open]="true"
                [title]="true"
                [footer]="true"
                (cancel)="onCancel()">
            </app-order-list-client-view>
            
            <mat-divider *ngIf="requestList && (requestList.loading || !requestList.open || requestList.anyOpen)"></mat-divider>
            
            <app-product-list *ngIf="entity && activityId && hubId"
                #productList
                [activityId]="activityId"
                [hubId]="hubId"
                [currency]="entity.currency"
                (onAddToCart)="updateCart()">
            </app-product-list>

            <app-company-info-card
                class="footer"
                *ngIf="hub"
                [name]="hub.name"
                [address]="hub.address"
                [phone]="hub.phoneNumber"
                [email]="hub.email"
                [handler]="hub.urlHandler"
                [image]="hub.icon"
            >
                <app-social-card-actions 
                    *ngIf="entity && commentsPreview" 
                    [title]="entity.name"
                    [description]="entity.infoText"
                    [images]="entity.images"
                    [preview]="commentsPreview"
                    [views]="entity.views"
                    [requests]="entity.requestCount"
                    [id]="activityId"
                    [createdOn]="entity.createdOn"
                >
                </app-social-card-actions>
            </app-company-info-card>
        
            <button class="speed-dial-action-button" 
                *ngIf="cartListRef && cartListRef.summary.quantity > 0"
                (click)="goToCart()"  
                mat-fab 
                color="primary">
                <span class="counter">{{cartListRef.summary.quantity}}</span>&nbsp;
                <mat-icon>shopping_cart</mat-icon>
            </button>

        </div>
    </mat-tab>

    <mat-tab>
        <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
            <button (click)="goToProductList()" mat-button><span translate>tinyAll.back</span></button>
            <h2 class="title" translate>productList.cart</h2>
        </div>

        <div class="page-container" fxLayout="row wrap" fxLayoutAlign="center start">

            <app-cart-list *ngIf="entity && activityId && hubId"
                #cartList
                [activityId]="activityId"
                [hubId]="hubId"
                [currency]="entity.currency">
            </app-cart-list>

            <app-questions-summary *ngIf="showPriceSummary && form && hub && entity"
                [entityId]="entity.id"
                [hubId]="hub?.id"
                [items]="cartListRef.summary.total"
                [quantity]="cartListRef.summary.quantity"
                [questionAnswerMap]="questionAnswerMap"
                [totalPrice]="totalPrice"
                [originalQuestions]="questionsV1"
                [additionalCharges]="entity.additionalCharges"
                [currency]="entity.currency"
                [promoCodeForm]="form"
            >
            </app-questions-summary>

            <div fxLayout="row" fxFlex="100" fxLayoutAlign="end center">
                <button class="speed-dial-action-button-fix" 
                    *ngIf="entity && form && cartListRef && cartListRef && cartListRef.summary.quantity > 0"
                    (click)="goToCheckout()" 
                    mat-fab color="accent">
                    <span translate>productList.checkout</span>&nbsp;<mat-icon>payment</mat-icon>
                </button>
                <!--
                <button class="speed-dial-action-button-fix" 
                    *ngIf="entity && form && cartListRef && cartListRef && cartListRef.summary.quantity > 0 && (getQuestionsLength() > 0 || entity.gpsRequired || entity.phoneNumberRequired)"
                    (click)="goToCheckout()" 
                    mat-fab color="accent">
                    <span translate>productList.checkout</span>&nbsp;<mat-icon>payment</mat-icon>
                </button>
                -->

                <!--
                <button class="speed-dial-action-button-fix" 
                    *ngIf="entity && form && cartListRef && cartListRef && cartListRef.summary.quantity > 0 && (getQuestionsLength() === 0 && !entity.gpsRequired && !entity.phoneNumberRequired)"
                    (click)="sendRequest()" 
                    mat-fab color="accent">
                    <span translate>productList.place_order</span>&nbsp;<mat-icon>local_atm</mat-icon>
                </button>
                -->
            </div>
            
            <app-company-info-card
                class="footer"
                *ngIf="hub"
                [name]="hub.name"
                [address]="hub.address"
                [phone]="hub.phoneNumber"
                [email]="hub.email"
                [handler]="hub.urlHandler"
                [image]="hub.icon"
            >
                <app-social-card-actions 
                    *ngIf="entity && commentsPreview" 
                    [title]="entity.name"
                    [description]="entity.infoText"
                    [images]="entity.images"
                    [preview]="commentsPreview"
                    [views]="entity.views"
                    [requests]="entity.requestCount"
                    [id]="activityId"
                    [createdOn]="entity.createdOn"
                >
                </app-social-card-actions>
            </app-company-info-card>
        </div>
    </mat-tab>

    <mat-tab>
        <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
            <button (click)="goToCart()" mat-button><span translate>tinyAll.back</span></button>
            <h2 class="title" translate>productList.checkout</h2>
        </div>

        <div class="page-container" fxLayout="row wrap" fxLayoutAlign="center start">
            <app-address-selector *ngIf="form && entity?.addressRequired && formIsReady" [form]="form"></app-address-selector>
            <app-question-list-client-view 
                *ngIf="tabs && tabs.selectedIndex == 2 && entity && form && (getQuestionsLength() > 0 || entity.gpsRequired || entity.phoneNumberRequired || entity.fullnameRequired)" 
                class="question-list" 
                [form]="form" 
                [originalQuestions]="questionsV1"
                [showMap]="tabs && tabs.selectedIndex == 2"
            >
            </app-question-list-client-view> 

            <app-questions-summary *ngIf="showPriceSummary && form && hub && entity"
                [entityId]="entity.id"
                [hubId]="hub?.id"
                [items]="cartListRef.summary.total"
                [quantity]="cartListRef.summary.quantity"
                [questionAnswerMap]="questionAnswerMap"
                [totalPrice]="totalPrice"
                [originalQuestions]="questionsV1"
                [additionalCharges]="entity.additionalCharges"
                [currency]="entity.currency"
                [promoCodeForm]="form"
            >
            </app-questions-summary>

            <app-select-payment-method
                *ngIf="showPaymentMethods" 
                [form]="form"
                [hub]="hub"
                [totalPrice]="totalPrice"
                (sendRequest)="sendRequest()"
            >
            </app-select-payment-method>
            <app-azul-payment-form #azulPaymentEl></app-azul-payment-form>
            <div fxLayout="row" fxFlex="100" fxLayoutAlign="end center">
                <button *ngIf="showSendRequest" [disabled]="disableSendRequest" class="speed-dial-action-button-fix" (click)="sendRequest()" mat-fab color="accent">
                    <span translate>productList.place_order</span>&nbsp;<mat-icon>local_atm</mat-icon>
                </button>
            </div>
            <app-company-info-card
                *ngIf="showSendRequest && hub"
                [name]="hub.name"
                [address]="hub.address"
                [phone]="hub.phoneNumber"
                [email]="hub.email"
                [handler]="hub.urlHandler"
                [image]="hub.icon"
            >
                <app-social-card-actions 
                    *ngIf="entity && commentsPreview" 
                    [title]="entity.name"
                    [description]="entity.infoText"
                    [images]="entity.images"
                    [preview]="commentsPreview"
                    [views]="entity.views"
                    [requests]="entity.requestCount"
                    [id]="activityId"
                    [createdOn]="entity.createdOn"
                >
                </app-social-card-actions>
            </app-company-info-card>
        </div>
    </mat-tab>

    <!--<mat-tab>
        <ng-template matTabContent>
  
        </ng-template>
    </mat-tab>-->
</mat-tab-group>
</ng-container>
<ng-template #pageNotFound>
    <app-page-not-found (back)="back()"></app-page-not-found>
</ng-template>
<app-loading></app-loading>
