<div class="flex items-center border border-gray-400 rounded-lg p-2">
  <mat-icon class="text-gray-400">search</mat-icon>
  <input
    #searchInput
    type="text"
    placeholder="{{ 'formFields.search_dish' | translate }}"
    class="flex-grow border-none outline-none py-1 px-2.5"
    [value]="searchTerm"
  />
  <mat-icon
    *ngIf="searchTerm"
    (click)="clearSearch()"
    class="text-gray-400"
    >clear</mat-icon
  >
</div>
