<div class="question-list-container">
    <div fxLayout="column" fxLayoutGap="20px">
        <form novalidate [formGroup]="form" fxLayout="column" fxLayoutAlign="center">
            <mat-card class="card-container first-card-container"
                *ngIf="form.get('location') || form.get('clientPhoneNumber') || form.get('clientName')">
                <mat-card-content>
                    <mat-form-field class="first-field" *ngIf="form.get('clientName')">
                        <input matInput placeholder="{{'questionsDialog.user_fullname' | translate}}" formControlName="clientName" required />
                        <mat-error *ngIf="form.get('clientName').hasError('required')" translate>
                            questionsDialog.error_required</mat-error>
                    </mat-form-field>
                    <mat-form-field class="first-field" *ngIf="form.get('clientPhoneNumber')">
                        <input matInput placeholder="{{'questionsDialog.phone_number' | translate}}"
                            mask="(000) 000-0000" formControlName="clientPhoneNumber" required />
                        <mat-error *ngIf="form.get('clientPhoneNumber').hasError('required')" translate>
                            questionsDialog.error_required</mat-error>
                    </mat-form-field>
                    <div fxLayout="row" fxLayoutAlign="start end" *ngIf="form.get('location')">
                        &nbsp;&nbsp;
                        <button type="button" (click)="setLocation()" mat-mini-fab color="accent">
                            <mat-icon>add_location</mat-icon>
                        </button>
                        <span class="no-location-text" translate *ngIf="!form.get('location').value"
                            (click)="setLocation()">questionsDialog.set_location</span>
                        <span class="location-text" translate *ngIf="form.get('location').value"
                            (click)="setLocation()">buttons.current_location</span>
                    </div>
                    <mat-error class="location-error"
                        *ngIf="form.get('location') && (form.get('location').hasError('required') || !form.get('location').value)"
                        translate>formErrors.location_required_to_continue</mat-error>
                    <app-location-map *ngIf="showMap && form.get('location') && form.get('location').value"
                        [noCard]="true" [location]="form.get('location').value" [name]="" [launch]="false">
                    </app-location-map>

                </mat-card-content>
            </mat-card>
            <ng-container formGroupName="questionAnswerMap">
                <ng-container *ngFor="let questionItem of originalQuestions; index as i;">
                    <div class="flex-p">
                        <mat-card class="card-container">
                            <mat-card-content>
                                <p class="main-field">
                                    {{questionItem.question || 'questionsDialog.question' | translate}} <span
                                        *ngIf="questionItem.required" class="required-field">*</span>
                                </p>
                                <p *ngIf="questionItem.description" class="main-field-description">
                                    <mat-icon>info_outline</mat-icon> {{ questionItem.description }}
                                </p>
                                <div [ngSwitch]="questionItem.type">
                                    <div *ngSwitchCase="'TEXT'">
                                        <mat-form-field floatLabel="never">
                                            <input matInput placeholder="{{'questionsDialog.text_answer' | translate}}"
                                                [type]="questionItem.hidden ? 'password':'text'"
                                                [formControlName]="questionItem.question">
                                            <mat-error *ngIf="questionHasError(questionItem.question)" translate>
                                                questionsDialog.error_required</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div *ngSwitchCase="'NUMBER'">
                                        <mat-form-field floatLabel="never">
                                            <input matInput
                                                placeholder="{{'questionsDialog.number_answer' | translate}}"
                                                [ngClass]="{'encrypted' : questionItem.hidden}" type="number"
                                                (keydown)="validateNumberKey($event)"
                                                [formControlName]="questionItem.question">
                                            <mat-error *ngIf="questionHasError(questionItem.question)" translate>
                                                questionsDialog.error_required</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div *ngSwitchCase="'DATE'">
                                        <mat-form-field floatLabel="never">
                                            <mat-label><span translate>questionsDialog.choose_date</span></mat-label>
                                            <mat-datetimepicker-toggle class="mat-datetime-toggle" [for]="datePicker"
                                                matSuffix></mat-datetimepicker-toggle>
                                            <mat-datetimepicker [touchUi]="true" #datePicker type="date"
                                                [multiYearSelector]="true" [openOnFocus]="true" [timeInterval]="5"
                                                mode="auto"></mat-datetimepicker>
                                            <input matInput [formControlName]="questionItem.question"
                                                [matDatetimepicker]="datePicker" autocomplete="false" readonly>
                                            <mat-error *ngIf="questionHasError(questionItem.question)" translate>
                                                questionsDialog.error_required</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div *ngSwitchCase="'GEOPOINT'" (click)="openMap(questionItem)">
                                        <app-location-map [noCard]="true" [mapId]="'map' + i"  [launch]="false" [location]="questionItem.location">
                                        </app-location-map>

                                        <mat-form-field floatLabel="never" style="display: none;">
                                            <input matInput 
                                                [formControlName]="questionItem.question">
                                        </mat-form-field>
                                    </div>
                                    <div *ngSwitchCase="'TIME'">
                                        <mat-form-field floatLabel="never">
                                            <mat-label><span translate>questionsDialog.choose_time</span></mat-label>
                                            <mat-datetimepicker-toggle [for]="timePicker" matSuffix>
                                            </mat-datetimepicker-toggle>
                                            <mat-datetimepicker [touchUi]="true" #timePicker type="time"
                                                [twelvehour]="true" [openOnFocus]="true" [timeInterval]="5" mode="auto">
                                            </mat-datetimepicker>
                                            <input matInput [formControlName]="questionItem.question"
                                                [matDatetimepicker]="timePicker" autocomplete="false" readonly>
                                            <mat-error *ngIf="questionHasError(questionItem.question)" translate>
                                                questionsDialog.error_required</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div *ngSwitchCase="'DATETIME'">
                                        <mat-form-field floatLabel="never">
                                            <mat-label><span translate>questionsDialog.choose_datetime</span>
                                            </mat-label>
                                            <mat-datetimepicker-toggle [for]="datetimePicker" matSuffix>
                                            </mat-datetimepicker-toggle>
                                            <mat-datetimepicker [touchUi]="true" #datetimePicker type="datetime"
                                                [multiYearSelector]="true" [twelvehour]="true" [openOnFocus]="true"
                                                [timeInterval]="5" mode="auto"></mat-datetimepicker>
                                            <input matInput [formControlName]="questionItem.question"
                                                [matDatetimepicker]="datetimePicker" autocomplete="false" readonly>
                                            <mat-error *ngIf="questionHasError(questionItem.question)" translate>
                                                questionsDialog.error_required</mat-error>
                                        </mat-form-field>
                                    </div>

                                    <div *ngSwitchCase="'SINGLE_CHOICE'">
                                        <div class="field-type-options">

                                            <mat-radio-group [formControlName]="questionItem.question">
                                                <div class="field-type-option"
                                                    *ngFor="let option of getQuestionOptions(i); let j = index;"
                                                    fxLayout="row" fxLayoutAlign="space-between center">
                                                    <mat-radio-button [value]="option"
                                                        (click)="uncheckRadio(option, questionItem.question)">
                                                        {{option}}
                                                    </mat-radio-button>
                                                </div>
                                            </mat-radio-group>
                                            <mat-error
                                                *ngIf="questionHasError(questionItem.question) && questionIsTouched(questionItem.question)"
                                                translate>questionsDialog.error_required</mat-error>
                                        </div>
                                    </div>

                                    <div *ngSwitchCase="'SINGLE_CHOICE_PRICE'">
                                        <div class="field-type-options">

                                            <mat-radio-group [formControlName]="questionItem.question">
                                                <div class="field-type-option"
                                                    *ngFor="let option of getPriceQuestionOptions(i); let j = index;"
                                                    fxLayout="row nowrap" fxLayoutAlign="space-between center">
                                                    <mat-radio-button [value]="option"
                                                        (click)="uncheckRadio(option, questionItem.question)">
                                                        {{option}} - ({{getPriceQuestionOptionsValue(i, option) |
                                                        currency:'$'}})
                                                    </mat-radio-button>
                                                </div>
                                            </mat-radio-group>
                                            <mat-error
                                                *ngIf="questionHasError(questionItem.question) && questionIsTouched(questionItem.question)"
                                                translate>questionsDialog.error_required</mat-error>
                                        </div>

                                    </div>

                                    <div *ngSwitchCase="'MULTI_CHOICE'">
                                        <div class="field-type-options">
                                            <mat-form-field floatLabel="never" color="accent">
                                                <mat-label><span translate>questionsDialog.choose</span></mat-label>
                                                <mat-select [formControlName]="questionItem.question" multiple>
                                                    <mat-option class="field-type-option"
                                                        *ngFor="let option of getQuestionOptions(i); let j = index;"
                                                        [value]="option">{{option}}</mat-option>
                                                </mat-select>
                                                <mat-error *ngIf="questionHasError(questionItem.question)" translate>
                                                    questionsDialog.error_required</mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>

                                    <div *ngSwitchCase="'MULTI_CHOICE_PRICE'">
                                        <div class="field-type-options">
                                            <mat-form-field floatLabel="never" color="accent">
                                                <mat-label><span translate>questionsDialog.choose</span></mat-label>
                                                <mat-select [formControlName]="questionItem.question" multiple>
                                                    <mat-option class="field-type-option"
                                                        *ngFor="let option of getPriceQuestionOptions(i); let j = index;"
                                                        [value]="option">{{option}} - ({{getPriceQuestionOptionsValue(i,
                                                        option) | currency:'$'}})</mat-option>
                                                </mat-select>
                                                <mat-error *ngIf="questionHasError(questionItem.question)" translate>
                                                    questionsDialog.error_required</mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>

                                    <div *ngSwitchCase="'IMAGE'">
                                        <input hidden="true" type="file" accept="image/*"
                                            (change)="saveImage($any($event).target.files, questionItem.question)"
                                            #imagesInput>
                                        <div fxLayout="row" fxLayoutAlign="space-between center">
                                            <div fxLayout="column" fxLayoutAlign="center center">
                                                <button class="image-input-upload"
                                                    (click)="uploadImage($event, imagesInput)" mat-stroked-button
                                                    color="accent">
                                                    <mat-icon>cloud_upload</mat-icon>&nbsp;&nbsp;
                                                    <span translate>questionsDialog.image_answer</span>
                                                </button>
                                                <button *ngIf="questionValue(questionItem.question)"
                                                    class="image-input-upload remove-image-button"
                                                    (click)="removeImage(questionItem.question)" mat-stroked-button
                                                    color="warn">
                                                    <mat-icon>delete_forever</mat-icon>&nbsp;&nbsp;
                                                    <span translate>questionsDialog.remove</span>
                                                </button>
                                            </div>

                                            <div class="add-image-link" fxLayout="row" fxLayoutAlign="center center">
                                                <img class="placeholder-image" *ngIf="questionValue(questionItem.question)" (click)="imagesInput.click()"
                                                    [src]="questionValue(questionItem.question)" alt="answer"/>
                                            </div>
                                        </div>
                                        <mat-error
                                            *ngIf="questionHasError(questionItem.question)  && questionIsTouched(questionItem.question)"
                                            translate>questionsDialog.error_required</mat-error>
                                        <mat-error class="error" *ngIf="maxSizeErrors[questionItem.question]" translate>{{ 'formErrors.image_max_size' | translate:{ size: maxImageSize } }}</mat-error>
                                    </div>
                                    <div *ngSwitchCase="'GROUP'">
                                        <div class="field-type-options">
                                            <mat-radio-group [formControlName]="questionItem.question"
                                                (change)="conditionalChange($event, i)">
                                                <div class="field-type-option"
                                                    *ngFor="let option of getGroupQuestionOptions(i); let j = index;"
                                                    fxLayout="row" fxLayoutAlign="space-between center">
                                                    <mat-radio-button [value]="option"
                                                        (click)="uncheckConditionalRadio(option, questionItem.question, i)">
                                                        {{option}}
                                                    </mat-radio-button>
                                                </div>
                                            </mat-radio-group>
                                            <mat-error
                                                *ngIf="questionHasError(questionItem.question) && questionIsTouched(questionItem.question)"
                                                translate>questionsDialog.error_required</mat-error>
                                        </div>
                                    </div>

                                    <span *ngSwitchDefault>...</span>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                    <ng-container *ngIf="questionItem.type === 'GROUP' && questionValue(questionItem.question)">
                        <div class="flex-p"
                            *ngFor="let innerQuestionItem of questionItem.groups[questionValue(questionItem.question)]; let K = index;">
                            <mat-card class="card-container">
                                <mat-card-content>
                                    <p class="main-field">
                                        {{innerQuestionItem.question || 'questionsDialog.question' | translate}} <span
                                            *ngIf="innerQuestionItem.required" class="required-field">*</span>
                                    </p>
                                    <p *ngIf="innerQuestionItem.description" class="main-field-description">
                                        <mat-icon>info_outline</mat-icon> {{ innerQuestionItem.description }}
                                    </p>
                                    <div [ngSwitch]="innerQuestionItem.type">
                                        <div *ngSwitchCase="'TEXT'">
                                            <mat-form-field floatLabel="never">
                                                <input matInput
                                                    placeholder="{{'questionsDialog.text_answer' | translate}}"
                                                    [type]="innerQuestionItem.hidden ? 'password':'text'"
                                                    [formControlName]="innerQuestionItem.question">
                                                <mat-error *ngIf="questionHasError(innerQuestionItem.question)"
                                                    translate>questionsDialog.error_required</mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div *ngSwitchCase="'NUMBER'">
                                            <mat-form-field floatLabel="never">
                                                <input matInput
                                                    placeholder="{{'questionsDialog.number_answer' | translate}}"
                                                    [type]="innerQuestionItem.hidden ? 'password':'number'"
                                                    [formControlName]="innerQuestionItem.question">
                                                <mat-error *ngIf="questionHasError(innerQuestionItem.question)"
                                                    translate>questionsDialog.error_required</mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div *ngSwitchCase="'DATE'">
                                            <mat-form-field floatLabel="never">
                                                <mat-label><span translate>questionsDialog.choose_date</span>
                                                </mat-label>
                                                <mat-datetimepicker-toggle class="mat-datetime-toggle"
                                                    [for]="datePicker" matSuffix></mat-datetimepicker-toggle>
                                                <mat-datetimepicker [touchUi]="true" #datePicker type="date"
                                                    [multiYearSelector]="true" [openOnFocus]="true" [timeInterval]="5"
                                                    mode="auto"></mat-datetimepicker>
                                                <input matInput [formControlName]="innerQuestionItem.question"
                                                    [matDatetimepicker]="datePicker" autocomplete="false" readonly>
                                                <mat-error *ngIf="questionHasError(innerQuestionItem.question)"
                                                    translate>questionsDialog.error_required</mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div *ngSwitchCase="'GEOPOINT'" (click)="openMap(innerQuestionItem)">
                                            <app-location-map [noCard]="true" [mapId]="'innermap' + i"  [launch]="false" [location]="innerQuestionItem.location">
                                            </app-location-map>
    
                                            <mat-form-field floatLabel="never" style="display: none;">
                                                <input matInput 
                                                    [formControlName]="innerQuestionItem.question">
                                            </mat-form-field>
                                        </div>
                                        <div *ngSwitchCase="'TIME'">
                                            <mat-form-field floatLabel="never">
                                                <mat-label><span translate>questionsDialog.choose_time</span>
                                                </mat-label>
                                                <mat-datetimepicker-toggle [for]="timePicker" matSuffix>
                                                </mat-datetimepicker-toggle>
                                                <mat-datetimepicker [touchUi]="true" #timePicker type="time"
                                                    [twelvehour]="true" [openOnFocus]="true" [timeInterval]="5"
                                                    mode="auto"></mat-datetimepicker>
                                                <input matInput [formControlName]="innerQuestionItem.question"
                                                    [matDatetimepicker]="timePicker" autocomplete="false" readonly>
                                                <mat-error *ngIf="questionHasError(innerQuestionItem.question)"
                                                    translate>questionsDialog.error_required</mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div *ngSwitchCase="'DATETIME'">
                                            <mat-form-field floatLabel="never">
                                                <mat-label><span translate>questionsDialog.choose_datetime</span>
                                                </mat-label>
                                                <mat-datetimepicker-toggle [for]="datetimePicker" matSuffix>
                                                </mat-datetimepicker-toggle>
                                                <mat-datetimepicker [touchUi]="true" #datetimePicker type="datetime"
                                                    [multiYearSelector]="true" [twelvehour]="true" [openOnFocus]="true"
                                                    [timeInterval]="5" mode="auto"></mat-datetimepicker>
                                                <input matInput [formControlName]="innerQuestionItem.question"
                                                    [matDatetimepicker]="datetimePicker" autocomplete="false" readonly>
                                                <mat-error *ngIf="questionHasError(innerQuestionItem.question)"
                                                    translate>questionsDialog.error_required</mat-error>
                                            </mat-form-field>
                                        </div>

                                        <div *ngSwitchCase="'SINGLE_CHOICE'">
                                            <div class="field-type-options">

                                                <mat-radio-group [formControlName]="innerQuestionItem.question">
                                                    <div class="field-type-option"
                                                        *ngFor="let option of getInnerQuestionOptions(i, questionValue(questionItem.question), K); let j = index;"
                                                        fxLayout="row" fxLayoutAlign="space-between center">
                                                        <mat-radio-button [value]="option"
                                                            (click)="uncheckRadio(option, innerQuestionItem.question)">
                                                            {{option}}
                                                        </mat-radio-button>
                                                    </div>
                                                </mat-radio-group>
                                                <mat-error
                                                    *ngIf="questionHasError(innerQuestionItem.question) && questionIsTouched(innerQuestionItem.question)"
                                                    translate>questionsDialog.error_required</mat-error>
                                            </div>
                                        </div>

                                        <div *ngSwitchCase="'SINGLE_CHOICE_PRICE'">
                                            <div class="field-type-options">

                                                <mat-radio-group [formControlName]="innerQuestionItem.question">
                                                    <div class="field-type-option"
                                                        *ngFor="let option of getPriceInnerQuestionOptions(i, questionValue(questionItem.question), K); let j = index;"
                                                        fxLayout="row" fxLayoutAlign="space-between center">
                                                        <mat-radio-button [value]="option"
                                                            (click)="uncheckRadio(option, innerQuestionItem.question)">
                                                            {{option}} - ({{getPriceInnerQuestionOptionsValue(i,
                                                            questionValue(questionItem.question), K, option) |
                                                            currency:'$'}})
                                                        </mat-radio-button>
                                                    </div>
                                                </mat-radio-group>
                                                <mat-error
                                                    *ngIf="questionHasError(innerQuestionItem.question) && questionIsTouched(innerQuestionItem.question)"
                                                    translate>questionsDialog.error_required</mat-error>
                                            </div>
                                        </div>

                                        <div *ngSwitchCase="'MULTI_CHOICE'">
                                            <div class="field-type-options">
                                                <mat-form-field floatLabel="never" color="accent">
                                                    <mat-label><span translate>questionsDialog.choose</span></mat-label>
                                                    <mat-select [formControlName]="innerQuestionItem.question" multiple>
                                                        <mat-option class="field-type-option"
                                                            *ngFor="let option of getInnerQuestionOptions(i, questionValue(questionItem.question), K); let j = index;"
                                                            [value]="option">{{option}}</mat-option>
                                                    </mat-select>
                                                    <mat-error *ngIf="questionHasError(innerQuestionItem.question)"
                                                        translate>questionsDialog.error_required</mat-error>
                                                </mat-form-field>
                                            </div>
                                        </div>

                                        <div *ngSwitchCase="'MULTI_CHOICE_PRICE'">
                                            <div class="field-type-options">
                                                <mat-form-field floatLabel="never" color="accent">
                                                    <mat-label><span translate>questionsDialog.choose</span></mat-label>
                                                    <mat-select [formControlName]="innerQuestionItem.question" multiple>
                                                        <mat-option class="field-type-option"
                                                            *ngFor="let option of getPriceInnerQuestionOptions(i, questionValue(questionItem.question), K); let j = index;"
                                                            [value]="option">
                                                            {{option}} - ({{getPriceInnerQuestionOptionsValue(i,
                                                            questionValue(questionItem.question), K, option) |
                                                            currency:'$'}})
                                                        </mat-option>
                                                    </mat-select>
                                                    <mat-error *ngIf="questionHasError(innerQuestionItem.question)"
                                                        translate>questionsDialog.error_required</mat-error>
                                                </mat-form-field>
                                            </div>
                                        </div>

                                        <div *ngSwitchCase="'IMAGE'">
                                            <input hidden="true" type="file" accept="image/*"
                                                (change)="saveImage($any($event).target.files, innerQuestionItem.question)"
                                                #imagesInput>
                                            <div fxLayout="row" fxLayoutAlign="space-between center">
                                                <div fxLayout="column" fxLayoutAlign="center center">
                                                    <button class="image-input-upload"
                                                        (click)="uploadImage($event, imagesInput)" mat-stroked-button
                                                        color="accent">
                                                        <mat-icon>cloud_upload</mat-icon>&nbsp;&nbsp;
                                                        <span translate>questionsDialog.image_answer</span>
                                                    </button>
                                                    <button *ngIf="questionValue(innerQuestionItem.question)"
                                                        class="image-input-upload remove-image-button"
                                                        (click)="removeImage(innerQuestionItem.question)"
                                                        mat-stroked-button color="warn">
                                                        <mat-icon>delete_forever</mat-icon>&nbsp;&nbsp;
                                                        <span translate>questionsDialog.remove</span>
                                                    </button>
                                                </div>

                                                <div class="add-image-link" fxLayout="row"
                                                    fxLayoutAlign="center center">
                                                    <img class="placeholder-image" *ngIf="questionValue(questionItem.question)" (click)="imagesInput.click()"
                                                        [src]="questionValue(innerQuestionItem.question)" alt="answer" />
                                                </div>
                                            </div>
                                            <mat-error
                                                *ngIf="questionHasError(innerQuestionItem.question)  && questionIsTouched(innerQuestionItem.question)"
                                                translate>questionsDialog.error_required</mat-error>
                                            <mat-error class="error" *ngIf="maxSizeErrors[innerQuestionItem.question]" translate>{{ 'formErrors.image_max_size' | translate:{ size: maxImageSize } }}</mat-error>
                                        </div>
                                        <span *ngSwitchDefault>...</span>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </ng-container>
                </ng-container>

            </ng-container>
        </form>
    </div>
</div>