<div *ngIf="plainText && plainText.length >= maxCharactersBeforeCollapsible && collapsible" class="flex-p hide" fxFlex="100" [class.hide]="hideCard">
    <mat-expansion-panel [expanded]="expandedByDefault">
        <mat-expansion-panel-header>
            <mat-panel-title class="text-base !text-gray-600 font-medium">{{title || 'formFields.description' | translate}}</mat-panel-title>
        </mat-expansion-panel-header>
        <app-rich-text-view [text]="originalText"></app-rich-text-view>
    </mat-expansion-panel>
</div>

<div *ngIf="plainText && (plainText.length < maxCharactersBeforeCollapsible || !collapsible)" class="flex-p" fxFlex="100" [class.hide]="hideCard">
    <mat-card class="card-container">
        <mat-card-content>
            <app-rich-text-view [text]="originalText"></app-rich-text-view>
        </mat-card-content>
    </mat-card>
</div>