import { NgModule } from '@angular/core';
import { CurrencyMaskDirective } from './currency-mask.directive';
import { DraggableScrollDirective } from './draggable-scroll.directive';

@NgModule({
  imports: [],
  providers: [],
  declarations: [CurrencyMaskDirective, DraggableScrollDirective],
  exports: [CurrencyMaskDirective, DraggableScrollDirective],
})
export class DirectivesModule {}
