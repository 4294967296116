import { RouteConstants } from '../../../app.constants';
import { Menu } from './menu.model';

export const verticalMenuItems = [
  new Menu(1, 'labels.home', '/~home', null, 'home', null, false, 0, null),
  new Menu(2, 'home.create_or_edit_your_business', '~hub/owner', null, 'business_center', null, false, 0, null),
  new Menu(3, 'QR Code Reader', null, null, 'qr_code_scanner', null, false, 0, 'qr-reader'),
  new Menu(4, 'titles.my_addresses', `${RouteConstants.settings}/addresses`, null, 'location_on', null, false, 0, null),
  new Menu(5, 'titles.my_orders', RouteConstants.requests, null, 'list_alt', null, false, 0, null),
  new Menu(6, 'titles.my_promo_codes', RouteConstants.userpromocodes, null, 'confirmation_number', null, false, 0, null),
  new Menu(7, 'userMenu.user_preferences', null, null, 'person', null, false, 0, 'user-preferences'),
  new Menu(8, 'userMenu.login_or_register', null, null, 'power_settings_new', null, false, 0, 'login'),
  new Menu(9, 'userMenu.log_out', null, null, 'power_settings_new', null, false, 0, 'logout'),

  /*
  new Menu(2, 'Users', '/users', null, 'supervisor_account', null, false, 0),
  new Menu(40, 'Pages', null, null, 'library_books', null, true, 0),
  new Menu(43, 'Login', '/login', null, 'exit_to_app', null, false, 40),
  new Menu(44, 'Register', '/register', null, 'person_add', null, false, 40),
  new Menu(46, 'Page Not Found', '/pagenotfound', null, 'error_outline', null, false, 40),
  new Menu(47, 'Error', '/error', null, 'warning', null, false, 40),
  new Menu(48, 'Landing', '/landing', null, 'filter', null, false, 40),
  new Menu (66, 'Maps', null, null, 'map', null, true, 0),
  */
]

export const horizontalMenuItems = [ ]
