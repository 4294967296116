<div class="header" fxLayout="row" fxLayoutAlign="space-between center" *ngIf="!activityIdParam">
    <button (click)="back()" mat-button><span translate>tinyAll.back</span></button>
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <app-client-view-button [url]="qrCode"></app-client-view-button>
        <button class="menu-button" mat-icon-button [matMenuTriggerFor]="menu"><mat-icon>more_vert</mat-icon></button>
        <mat-menu #menu="matMenu">
            <button class="save" mat-menu-item (click)="onSubmit()" [disabled]="!form || (!form.dirty && !questionsForm.dirty && !collaboratorsForm.dirty) || form.invalid || questionsForm.invalid || collaboratorsForm.invalid || hubId == null || hubId == 'undifined'"><span translate>buttons.save</span></button>
            <button class="delete" mat-menu-item (click)="canDelete()" *ngIf="activityId"><span translate>buttons.delete</span></button>
        </mat-menu>
    </div>
</div>

<div class="page-container" fxLayout="row wrap" fxLayoutAlign="center start">
    <app-activity-breadcrumb *ngIf="!activityIdParam" [categoryId]="categoryId" type="ACTIVITY_EVENT" (onBack)="back()"></app-activity-breadcrumb>
    <app-qr-generator-card *ngIf="qrCode" [text]="qrCode" [imageUrl]="images[0]"></app-qr-generator-card>
    <app-url-handler-copy *ngIf="qrCode" [url]="qrCode"></app-url-handler-copy>
    <app-event-stats-card *ngIf="activityId && form" [availableTicketCount]="availableTicketCount.value" [capacity]="capacity.value"></app-event-stats-card>
    <app-url-handler-example *ngIf="collaboratorUrl" title="labels.scanner_url" [urlHandler]="collaboratorUrl" [copy]="true" [isLink]="true"></app-url-handler-example>
    <form novalidate *ngIf="form" [formGroup]="form" fxLayout="column" fxLayoutAlign="center">
        <div class="flex-p" fxFlex="100">
            <mat-card class="card-container">
                <mat-card-content>
                    <div fxLayout="column" fxLayoutAlign="center">
                        <div hidden>
                            <input matInput type="text" formControlName="id">
                            <input matInput type="text" formControlName="userId">
                            <input matInput type="text" formControlName="hubId">
                            <input matInput type="text" formControlName="categoryId">
                            <input matInput type="text" formControlName="root">
                        </div>
    
                        <mat-form-field class="activity-name">
                            <input matInput placeholder="{{'activityRequest.name' | translate}}" formControlName="name">
                            <mat-error *ngIf="name.hasError('required')" translate>activityRequest.error_name</mat-error>
                        </mat-form-field>
    
                        <div class="relative">
                            <app-swiper-management [images]="images" 
                                [form]="form"
                                [allowVideo]="true">
                            </app-swiper-management>
                            <apan *ngIf="activityId && active.value" class="bg-accent rounded-full px-2 py-1 text-white z-10 absolute top-[7px] left-[-5px] shadow-lg" translate>labels.active</apan>
                            <apan *ngIf="activityId && !active.value" class="bg-red-600 rounded-full px-2 py-1 text-white z-10 absolute top-[7px] left-[-5px] shadow-lg" translate>labels.inactive</apan>
                        </div>

                        <app-rich-text-editor [form]="form" fieldName="infoText" mode="edit" placeholder="{{'formFields.description' | translate}}"></app-rich-text-editor>
                        <mat-error *ngIf="info.hasError('required')" translate>activityRequest.error_info</mat-error>
                        <div style="height:10px"></div>

                        <div fxLayout="column" fxLayoutAlign="center" fxLayoutGap="12px">
                            <mat-form-field class="event-date">
                                <mat-label><span translate>formFields.event_datetime</span></mat-label>
                                <mat-datetimepicker-toggle [for]="datetimePicker" matSuffix></mat-datetimepicker-toggle>
                                <mat-datetimepicker [touchUi]="true" #datetimePicker type="datetime" [multiYearSelector]="true" [twelvehour]="true" [openOnFocus]="true" [timeInterval]="5" mode="auto"></mat-datetimepicker>
                                <app-info-tooltip matSuffix info="tooltip.event_datetime" infoClass="secondary"></app-info-tooltip>
                                <input matInput formControlName="eventDate" [matDatetimepicker]="datetimePicker" autocomplete="false" readonly [min]="today">
                                <mat-error *ngIf="eventDate.hasError('required')" translate>formErrors.event_date</mat-error>
                                <mat-error *ngIf="eventDate.hasError('matDatepickerMin')" translate>formErrors.event_date_is_past</mat-error>
                            </mat-form-field>
                            <app-address-picker [field]="address" [coordinates]="hubLocation" (addressChanged)="updateLocation($event)"></app-address-picker>
                            <div class="map-container" (click)="openMap()">
                                <div fxLayout="column">
                                    <div class="label" translate>subtitles.event_location<app-info-tooltip info="tooltip.event_location" infoClass="primary"></app-info-tooltip></div>
                                    <mat-error *ngIf="location.hasError('required')" translate>formErrors.event_location</mat-error>
                                    <div style="height:10px"></div>
                                    <app-location-map
                                        [location]="location.value"
                                        [noCard]="true">
                                    </app-location-map>
                                </div>
                            </div>

                            <h3 *ngIf="activityId" class="capacity"><span translate>labels.max_capacity</span>: {{capacity.value}}</h3>
                            <mat-form-field>
                                <app-info-tooltip matSuffix info="tooltip.available_ticket_count" infoClass="secondary"></app-info-tooltip>
                                <mat-label><span translate>formFields.available_ticket_count</span></mat-label>
                                <input type="number" matInput formControlName="availableTicketCount">
                                <mat-error *ngIf="availableTicketCount.hasError('required')" translate>formErrors.available_ticket_count_required</mat-error>
                                <mat-error *ngIf="availableTicketCount.hasError('minNumber')" translate>formErrors.available_ticket_count_min</mat-error>
                                <mat-error *ngIf="availableTicketCount.hasError('maxNumber')" translate>formErrors.available_ticket_count_max</mat-error>
                            </mat-form-field>
                            <mat-form-field>
                                <app-info-tooltip matSuffix info="tooltip.max_tickets_per_user" infoClass="secondary"></app-info-tooltip>
                                <mat-label><span translate>formFields.max_tickets_per_user</span></mat-label>
                                <input type="number" matInput formControlName="maxTicketsPerUser">
                                <mat-error *ngIf="maxTicketsPerUser.hasError('required')" translate>formErrors.max_tickets_per_user_required</mat-error>
                                <mat-error *ngIf="maxTicketsPerUser.hasError('minNumber')" translate>formErrors.max_tickets_per_user_min</mat-error>
                                <mat-error *ngIf="maxTicketsPerUser.hasError('maxNumber')" translate>formErrors.max_tickets_per_user_max</mat-error>
                                <mat-error *ngIf="maxTicketsPerUser.hasError('maxLimitExceeded')" translate>formErrors.max_tickets_per_limit_exceeded</mat-error>
                            </mat-form-field>
                            <mat-form-field>
                                <app-info-tooltip matSuffix info="tooltip.ticket_price" infoClass="secondary"></app-info-tooltip>
                                <mat-label><span translate>formFields.ticket_price</span></mat-label>
                                <input type="number"
                                    matInput
                                    formControlName="ticketPrice">
                                    <span matPrefix>$&nbsp;</span>
                                <mat-error *ngIf="ticketPrice.hasError('required')" translate>formErrors.ticket_price</mat-error>
                            </mat-form-field>
                            <section fxLayout="column" fxLayoutAlign="space-between stretch">
                                <app-warning-message *ngIf="!active.value"
                                    message="messages.inactive_activity">
                                </app-warning-message>
                                <mat-checkbox formControlName="active" [class]="getHighlightCheckbox('active')">
                                    <span translate>activityRequest.active<app-info-tooltip class="checkbox-tooltip" info="tooltip.active_checkbox" infoClass="secondary"></app-info-tooltip></span>
                                </mat-checkbox>
                            </section>
                            <section fxLayoutAlign="space-between stretch">
                                <mat-checkbox formControlName="unListed" [class]="getHighlightCheckbox('unListed')">
                                    <span translate>formFields.hidden<app-info-tooltip class="checkbox-tooltip" info="tooltip.hidden_checkbox" infoClass="secondary"></app-info-tooltip></span>
                                </mat-checkbox>
                            </section>
                            <section fxLayoutAlign="space-between stretch" fxLayout="column">
                                <mat-checkbox formControlName="includeInMenu" [class]="getHighlightCheckbox('includeInMenu')">
                                    <span translate>formFields.add_to_navigation_menu<app-info-tooltip class="checkbox-tooltip" info="tooltip.in_menu_checkbox" infoClass="secondary"></app-info-tooltip></span>
                                </mat-checkbox>
                                <div *ngIf="showNavigationMenuLink">
                                    <span class="full-menu-text" translate>messages.navigation_menu_is_full</span>
                                    <button (click)="gotoNavigationMenu()" mat-button color="accent"><span translate>buttons.update</span></button>
                                </div>
                            </section>
                            <section fxLayoutAlign="space-between stretch">
                                <mat-checkbox formControlName="addressRequired" [class]="getHighlightCheckbox('addressRequired')">
                                    <span translate>activityRequest.address<app-info-tooltip class="checkbox-tooltip" info="tooltip.address_required_checkbox" infoClass="secondary"></app-info-tooltip></span>
                                </mat-checkbox>
                            </section>
                            <section fxLayoutAlign="space-between stretch">
                                <mat-checkbox formControlName="fullnameRequired" [class]="getHighlightCheckbox('fullnameRequired')">
                                    <span translate>activityRequest.fullname<app-info-tooltip class="checkbox-tooltip" info="tooltip.fullname_required_checkbox" infoClass="secondary"></app-info-tooltip></span>
                                </mat-checkbox>
                            </section>
                            <section fxLayoutAlign="space-between stretch">
                                <mat-checkbox formControlName="clonable" [class]="getHighlightCheckbox('clonable')">
                                    <span translate>formFields.clonable<app-info-tooltip class="checkbox-tooltip" info="tooltip.clonable_checkbox" infoClass="secondary"></app-info-tooltip></span>
                                </mat-checkbox>
                            </section>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>

        <app-confirmation-message-management [form]="form"></app-confirmation-message-management>

        <app-additional-charges-management [form]="additionalChargesForm"></app-additional-charges-management>
        
        <app-collaborators-management [form]="collaboratorsForm">
        </app-collaborators-management>

        <app-question-list-management 
            *ngIf="questionsForm && selectedInnerQuestion"
            [questionsForm]="questionsForm" 
            [selectedInnerQuestion]="selectedInnerQuestion"
            class="question-list">
        </app-question-list-management>
    </form>
    <button
        class="save-speed-dial-action-button-fix"
        (click)="onSubmit()"
        mat-fab
        [disabled]="!form || (!form.dirty && !originId && !hubIdParam && !questionsForm.dirty && !collaboratorsForm.dirty) || form.invalid || questionsForm.invalid || collaboratorsForm.invalid || hubId == null || hubId == 'undifined'"
        color="primary"
    >
        <span translate>tinyAll.save</span>
        <mat-icon>save</mat-icon>
    </button>
</div>
<app-loading></app-loading>
