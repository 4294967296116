import {
  Component,
  ViewChild,
  OnDestroy,
  ElementRef,
  Output,
  EventEmitter,
  AfterViewInit,
  Input,
} from '@angular/core';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'app-dish-search-autocomplete',
  templateUrl: './dish-search-autocomplete.component.html',
})
export class DishSearchAutocompleteComponent
  implements OnDestroy, AfterViewInit
{
  @ViewChild('searchInput', { static: true }) searchInput!: ElementRef;

  @Input() searchTerm: string = '';
  @Output() onChange = new EventEmitter();

  
  private searchSubscription!: Subscription;
  private destroy$ = new Subject<void>();

  ngAfterViewInit() {
    this.searchSubscription = fromEvent(this.searchInput.nativeElement, 'input')
      .pipe(
        map((event: Event) => (event.target as HTMLInputElement).value),
        debounceTime(300), // Adjust debounce time as needed (milliseconds)
        distinctUntilChanged()
      )
      .subscribe((term) => {
        this.onChange.emit(term);
      });
  }

  ngOnDestroy() {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    this.destroy$.next();
    this.destroy$.complete();
  }

  clearSearch() {
    //this.searchTerm = '';
    //this.searchInput.nativeElement.value = ''; // Update the input field directly
    this.onChange.emit('');
  }
}
