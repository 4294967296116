<div class="relative">
  <button
    *ngIf="showLeftArrow"
    (click)="scrollLeft()"
    class="absolute left-0 top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-lg flex"
  >
    <mat-icon class="text-accent">chevron_left</mat-icon>
  </button>
  <div class="overflow-x-auto no-scrollbar" #sectionContainer appDraggableScroll (scroll)="onScroll($event)">
    <div #sectionList class="flex whitespace-nowrap py-2 px-4 w-fit">
      <button
        *ngFor="let section of sections"
        (click)="selectSection(section)"
        [class.bg-primary]="selectedSection === section"
        [class.text-white]="selectedSection === section"
        class="bg-gray-200 px-4 py-1 rounded-full mx-1 cursor-pointer"
      >
        {{ section }}
      </button>
    </div>
  </div>
  <button
    *ngIf="showRightArrow"
    (click)="scrollRight()"
    class="absolute right-0 top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-lg flex"
  >
    <mat-icon class="text-accent">chevron_right</mat-icon>
  </button>
</div>
