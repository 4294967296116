import { Component, Input, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpService } from '../../service/http.service';
import EntityTypeToUrlMap from '../../util/entity-type-to-url-map';
import { HubEntity } from '../../models/hub';
import { ContentType } from '../../models/content-type-enum';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { CreateActivityInfoCommand } from '../../models/activity-info';
import { CreateActivityRequestCommand } from '../../models/activity-request';
import { CreateActivityReservationCommand } from '../../models/activity-reservation';
import { CreateActivityProductListingCommand } from '../../models/activity-product-listing';
import { CreateActivityMenuCommand } from '../../models/activity-menu';
import { CreateActivityEventCommand } from '../../models/activity-event';
import { CreateCategoryCommand } from '../../models/category';
import { ImageService } from '../../service/image.service';
import { RichTextEditorComponent } from '../rich-text-editor/rich-text-editor.component';
import { PageEntity } from '../../models/types';
import { EntityType } from '../../models/entity-type-enum';
import { Router } from '@angular/router';
import { RouteConstants } from '../../app.constants';

@Component({
  selector: 'app-hub-cloner',
  templateUrl: './hub-cloner.component.html',
  styleUrls: ['./hub-cloner.component.scss'],
})
export class HubClonerComponent implements OnInit {
  @Input() handler;
  @Input() hubId;
  @Input() userId;
  @Input() showVideo = false;
  @Input() defaultActive = false;
  @Input() shouldGoHome = false;

  form;
  loading = false;
  cloning = false;
  hub: HubEntity;
  activities;
  baseURL = environment.appUrl;
  readonly apiUrl = environment.apiUrl;
  clonedActivity = 0;

  constructor(
    private router: Router,
    private httpService: HttpService,
    private translate: TranslateService,
    private fb: UntypedFormBuilder,
    protected imageService: ImageService
  ) {}

  ngOnInit() {
    if (location.hostname === 'localhost') {
      this.baseURL = 'https://localhost:4200';
    }
    this.createForm();
  }

  get hubUrl() {
    return this.form.get('hubUrl') as UntypedFormControl;
  }

  get hubUrlValue() {
    return this.form.get('hubUrl').value;
  }

  private createForm() {
    this.form = this.fb.group({
      hubUrl: ['', [Validators.required]],
    });
  }

  async searchHub() {
    if (this.hub) {
      this.hubUrl.enable();
      this.hub = null;
      return;
    }
    if (this.form.invalid) return;
    this.loading = true;
    const searchParams = this.hubUrlValue.split('?');
    const params = new URLSearchParams(
      searchParams.length > 1 ? searchParams[1] : searchParams[0]
    );

    let hubId;
    if (params.has('hubId')) {
      hubId = params.get('hubId');
    } else {
      const paths = new URL(this.hubUrlValue).pathname.split('/');
      this.hub = await this.getHubByHandlerEntity(paths[1]);
      if (this.hub) {
        hubId = this.hub.id;
      }
    }

    if (!hubId) {
      this.loading = false;
      this.hubUrl.setErrors({
        invalid: true,
      });
      return;
    }

    if (!this.hub) {
      this.hub = await this.getHubEntity(hubId);
    }

    if (!this.hub) {
      this.hubUrl.setErrors({
        invalid: true,
      });
      this.loading = false;
      return;
    }

    this.activities = await HubClonerComponent.getActCatEntity(hubId, this.httpService);

    if (!this.activities) {
      this.hubUrl.setErrors({
        noactivities: true,
      });
      this.loading = false;
      return;
    }

    this.hubUrl.disable();

    this.loading = false;
  }

  async getHubEntity(id: string) {
    const URL = `${environment.apiUrl}/hubs/v1/${id}`;
    try {
      return await firstValueFrom(this.httpService.doGet(URL));
    } catch (error) {
      return;
    }
  }

  async getHubByHandlerEntity(handler: string) {
    const URL = `${environment.apiUrl}/hubs/v1/search?querySearch=urlHandler:'${handler}'`;
    try {
      const response: PageEntity<HubEntity> = await firstValueFrom(
        this.httpService.doGet(URL)
      );
      return response.content[0];
    } catch (error) {
      return;
    }
  }

  static async getActCatEntity(id: string, httpService: HttpService) {
    const URL = `${environment.apiUrl}/actcatview/v1/client-search?page=0&size=500&querySearch=(hubId:'${id}' AND content.clonable:true AND ((contentType:'CATEGORY' AND content.restricted:false) OR (contentType!'CATEGORY' AND content.categoryId:'')))&sort=order,asc`;
    try {
      const response = await firstValueFrom(httpService.doGet(URL));
      if (response && response.content && response.content.length) {
        return response.content;
      } else {
        return;
      }
    } catch (error) {
      return;
    }
  }

  static async getInnerActCatEntity(id: string, categoryId: string, httpService: HttpService) {
    const URL = `${environment.apiUrl}/actcatview/v1/client-search?page=0&size=500&querySearch=(hubId:'${id}' AND content.clonable:true AND contentType!'${ContentType.CATEGORY}' AND content.categoryId:'${categoryId}')&sort=order,asc`;
    try {
      const response = await firstValueFrom(httpService.doGet(URL));
      if (response && response.content && response.content.length) {
        return response.content;
      } else {
        return [];
      }
    } catch (error) {
      return [];
    }
  }

  clearHubUrl() {
    this.hubUrl.setValue(null);
    this.hubUrl.setErrors(null);
  }

  async cloneHub() {
    this.clonedActivity = 0;
    if (!this.activities) return;
    this.cloning = true;
    for (const catAct of this.activities) {
      const activity = catAct.content;

      const url = `${this.apiUrl}/${
        EntityTypeToUrlMap[activity.entityType]
      }/v1`;
      const body = {
        ...HubClonerComponent.getActivityBody(activity),
        hubId: this.hubId,
        userId: this.userId,
        active: this.defaultActive,
        includeInMenu: false,
        order: 0,
      };

      try {
        const response = await firstValueFrom(
          this.httpService.doPost(url, body)
        );
        await HubClonerComponent.cloneImages(response, this.imageService);
        if (activity.entityType === EntityType.CATEGORY) {
          const innerActivities = await HubClonerComponent.getInnerActCatEntity(
            activity.hubId,
            activity.id,
            this.httpService
          );
          for (const innerCatAct of innerActivities) {
            const innerActivity = innerCatAct.content;
            const innerUrl = `${this.apiUrl}/${
              EntityTypeToUrlMap[innerActivity.entityType]
            }/v1`;
            const innerBody = {
              ...HubClonerComponent.getActivityBody(innerActivity),
              hubId: this.hubId,
              userId: this.userId,
              categoryId: response.id,
              active: this.defaultActive,
              includeInMenu: false,
              order: 0,
            };
            try {
              const innerResponse = await firstValueFrom(
                this.httpService.doPost(innerUrl, innerBody)
              );
              await HubClonerComponent.cloneImages(innerResponse, this.imageService);
            } catch (error) {}
          }
        }
      } catch (error) {
      } finally {
        this.clonedActivity += 1;
      }
    }
    await Swal.fire({
      titleText: this.translate.instant('titles.congratulations'),
      text: this.translate.instant('messages.cloning_complete'),
      icon: 'success',
      toast: true,
      position: 'top',
      iconColor: 'white',
      color: 'white',
      background: '#29A6A4',
      customClass: {
        popup: 'colored-toast',
      },
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });

    this.cloning = false;

    if (this.shouldGoHome) {
      this.goHome();
    } else {
      document.location.reload();
    }
  }

  static getActivityBody(activityToClone) {
    if (activityToClone.entityType === ContentType.ACTIVITY_INFO) {
      const {
        hubId,
        userId,
        name,
        infoText,
        order,
        active,
        unListed,
        includeInMenu,
        clonable,
        images,
      } = activityToClone;

      const body: CreateActivityInfoCommand = {
        hubId,
        userId,
        categoryId: '',
        name,
        infoText,
        order,
        active,
        unListed,
        includeInMenu,
        clonable,
        images,
      };
      return body;
    } else if (activityToClone.entityType === ContentType.ACTIVITY_REQUEST) {
      const {
        hubId,
        userId,
        name,
        infoText,
        order,
        active,
        unListed,
        includeInMenu,
        clonable,
        images,
        additionalCharges,
        phoneNumberRequired,
        gpsRequired,
        addressRequired,
        fullnameRequired,
        questions,
        newRequestMessage,
      } = activityToClone;

      const body: CreateActivityRequestCommand = {
        hubId,
        userId,
        categoryId: '',
        name,
        infoText,
        order,
        active,
        unListed,
        includeInMenu,
        clonable,
        images,
        additionalCharges,
        phoneNumberRequired,
        gpsRequired,
        addressRequired,
        fullnameRequired,
        questions,
        newRequestMessage,
      };
      return body;
    } else if (
      activityToClone.entityType === ContentType.ACTIVITY_RESERVATION
    ) {
      const {
        hubId,
        userId,
        name,
        infoText,
        order,
        active,
        unListed,
        includeInMenu,
        clonable,
        images,
        additionalCharges,
        phoneNumberRequired,
        addressRequired,
        fullnameRequired,
        gpsRequired,
        questions,
        slot,
        exceptions,
        scheduleBean,
        newRequestMessage,
      } = activityToClone;

      const body: CreateActivityReservationCommand = {
        hubId,
        userId,
        categoryId: '',
        name,
        infoText,
        order,
        active,
        unListed,
        includeInMenu,
        clonable,
        images,
        additionalCharges,
        phoneNumberRequired,
        addressRequired,
        fullnameRequired,
        gpsRequired,
        questions,
        slot,
        exceptions,
        scheduleBean,
        newRequestMessage,
      };

      return body;
    } else if (
      activityToClone.entityType === ContentType.ACTIVITY_PRODUCT_LISTING
    ) {
      const {
        hubId,
        userId,
        name,
        infoText,
        order,
        active,
        unListed,
        includeInMenu,
        clonable,
        images,
        additionalCharges,
        phoneNumberRequired,
        gpsRequired,
        addressRequired,
        fullnameRequired,
        questions,
        currency,
        trackingStatuses,
        newRequestMessage,
      } = activityToClone;

      const body: CreateActivityProductListingCommand = {
        hubId,
        userId,
        categoryId: '',
        name,
        infoText,
        order,
        active,
        unListed,
        includeInMenu,
        clonable,
        images,
        additionalCharges,
        phoneNumberRequired,
        gpsRequired,
        addressRequired,
        fullnameRequired,
        questions,
        currency,
        trackingStatuses,
        newRequestMessage,
      };
      return body;
    } else if (activityToClone.entityType === ContentType.ACTIVITY_MENU) {
      const {
        hubId,
        userId,
        name,
        infoText,
        order,
        active,
        unListed,
        includeInMenu,
        clonable,
        images,
        additionalCharges,
        sectionList,
        acceptOrder,
        tableRequired,
        qrCodeScanRequired,
        serviceTypes,
        newRequestMessage,
      } = activityToClone;

      const body: CreateActivityMenuCommand = {
        hubId,
        userId,
        categoryId: '',
        name,
        infoText,
        order,
        active,
        unListed,
        includeInMenu,
        clonable,
        images,
        additionalCharges,
        sectionList,
        acceptOrder,
        tableRequired,
        qrCodeScanRequired,
        serviceTypes: serviceTypes,
        newRequestMessage,
      };
      return body;
    } else if (activityToClone.entityType === ContentType.ACTIVITY_EVENT) {
      const eventDate = new Date();
      eventDate.setMonth(eventDate.getMonth() + 1);

      const {
        hubId,
        userId,
        name,
        infoText,
        order,
        active,
        unListed,
        includeInMenu,
        clonable,
        images,
        additionalCharges,
        questions,
        capacity,
        maxTicketsPerUser,
        ticketPrice,
        location,
        address,
        staffEmails,
        addressRequired,
        fullnameRequired,
        newRequestMessage,
      } = activityToClone;

      const body: CreateActivityEventCommand = {
        hubId,
        userId,
        categoryId: '',
        name,
        infoText,
        order,
        active,
        unListed,
        includeInMenu,
        clonable,
        images,
        additionalCharges,
        questions,
        eventDate: `${eventDate.toISOString().split('T')[0]}T00:00:00Z`,
        capacity,
        maxTicketsPerUser,
        ticketPrice,
        location,
        address,
        staffEmails,
        addressRequired,
        fullnameRequired,
        newRequestMessage,
      };
      return body;
    } else if (activityToClone.entityType === ContentType.CATEGORY) {
      const {
        hubId,
        userId,
        name,
        infoText,
        order,
        active,
        unListed,
        includeInMenu,
        clonable,
        images,
        additionalCharges,
        phoneNumberRequired,
        gpsRequired,
        addressRequired,
        fullnameRequired,
        questions,
        restricted,
        newRequestMessage,
      } = activityToClone;

      const body: CreateCategoryCommand = {
        hubId,
        userId,
        name,
        infoText,
        order,
        active,
        unListed,
        includeInMenu,
        clonable,
        images,
        additionalCharges,
        phoneNumberRequired,
        gpsRequired,
        addressRequired,
        fullnameRequired,
        questions,
        restricted,
        newRequestMessage,
      };
      return body;
    }
  }

  getPercentage() {
    if (this.clonedActivity === 0 || !this.activities) return 0;
    return (this.clonedActivity / this.activities.length) * 100;
  }

  static async cloneImages(activity, imageService: ImageService) {
    if (activity.images && activity.images[0]) {
      const clonedImages = [];
      for (const imageUrl of activity.images) {
        try {
          const clonedImage = await imageService.cloneImage(imageUrl);
          clonedImages.push(clonedImage);
        } catch (error) {
          console.log(error);
        }
      }
      activity.images = clonedImages;
    }
    const descriptionImages =
      RichTextEditorComponent.getImagesSrcFromHtmlString(activity.infoText);
    if (descriptionImages && descriptionImages.length > 0) {
      const clonedDescriptionImages = [];
      for (const imageUrl of descriptionImages) {
        try {
          const clonedImage = await imageService.cloneImage(imageUrl);
          clonedDescriptionImages.push(clonedImage);
        } catch (error) {
          console.log(error);
        }
      }
    }
  }

  goHome() {
    this.router.navigateByUrl(`${RouteConstants.hub}/owner`);
  }
}
