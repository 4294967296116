import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appDraggableScroll]',
})
export class DraggableScrollDirective {
  private isDragging = false;
  private startX = 0;
  private scrollLeft = 0;

  constructor(private el: ElementRef) {}

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    this.isDragging = true;
    this.startX = event.pageX - this.el.nativeElement.offsetLeft;
    this.scrollLeft = this.el.nativeElement.scrollLeft;
    this.el.nativeElement.style.scrollBehavior = 'auto';
  }

  @HostListener('mouseleave', ['$event'])
  @HostListener('mouseup', ['$event'])
  onMouseUp() {
    this.isDragging = false;
    this.el.nativeElement.style.scrollBehavior = 'smooth';
  }

  @HostListener('mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (!this.isDragging) return;
    const x = event.pageX - this.el.nativeElement.offsetLeft;
    const walk = (x - this.startX) * 2;
    this.el.nativeElement.scrollLeft = this.scrollLeft - walk;
  }
}
