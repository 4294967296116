<div fxLayout="column" fxLayoutAlign="center center">
  <ngx-kjua
    #qrcode
    [text]="text + (tableNumber ? '&table=' + tableNumber : '')"
    [quiet]="2"
    render="image"
    [size]="550"
    ecLevel="L"
    fill="#000"
    [crisp]="true"
    [ngClass]="{ hidden: withImage }"
  ></ngx-kjua>

  <canvas #canvas class="canvas" [ngClass]="{ hidden: !withImage }"></canvas>

  <h2 *ngIf="ticketStatus" [ngClass]="ticketStatus">
    <span class="label" translate>formFields.status</span>:
    {{ 'reportsFilter.' + ticketStatus | translate }}
  </h2>

  <app-url-handler-copy
    class="print:hidden"
    *ngIf="!isTicket"
    [url]="text + (tableNumber ? '&table=' + tableNumber : '')"
  ></app-url-handler-copy>

  <mat-card-actions>
    <div class="mb-6 print:hidden" *ngIf="isMenu">
      <button
        class="bg-primary text-white px-4 py-2 rounded-full cursor-pointer flex items-center"
        (click)="openTableNumberPopup()"
      >
        <label translate>buttons.add_table_number</label>
        <label
          *ngIf="tableNumber"
          class="p-1 ml-2 rounded-full bg-white text-primary w-7 h-7"
          >{{ tableNumber }}</label
        >
      </button>
    </div>
    <mat-slide-toggle
      *ngIf="imageUrl"
      class="no-print"
      [(ngModel)]="withImage"
      (change)="combineQrAndImage()"
    >
      <span translate>formFields.image_with_qr</span>
    </mat-slide-toggle>
  </mat-card-actions>

  <div
    *ngIf="qrcode"
    class="button-container"
    fxLayout="row"
    fxLayoutAlign="space-around center"
  >
    <button
      class="print-button no-print"
      (click)="print()"
      mat-fab
      color="accent"
    >
      <span translate>buttons.print</span>&nbsp;<mat-icon>print</mat-icon>
    </button>

    <button
      class="print-button no-print"
      mat-fab
      color="accent"
      (click)="saveImage()"
    >
      <span translate>buttons.save</span>&nbsp;<mat-icon>image</mat-icon>
    </button>
  </div>
</div>
